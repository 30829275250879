import React from 'react';
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckIcon from "@material-ui/icons/Check";
import { createKey, addFilter } from "./utils";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { getAllSuggestions } from '../../../API/jsonAPI';

import {
    action_change_filters,
} from '../../../Modules/redux/actions';

class TagFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentText: "",
            chosenValue: null,
            currentOptions: []
        };
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    async fetchOptions() {
        try {
            const currentOptions = await getAllSuggestions(window.sessionID, this.props.filterName);
            this.setState({ currentOptions })
        } catch (error) {
            console.warn(error)
            return
        }
    }

    componentDidMount() {
        this.fetchOptions();
    }

    handleConfirm() {

        const valObj = this.state.chosenValue // in this case its just a string

        if(!valObj) return

        const key = createKey(this.props.filterName)


        let displayValue = `${this.props.prefix}: ${valObj.split(",")[0]} ${this.props.suffix}`

        // console.log(this.state.currentOptions)
        // const newOptions = this.state.currentOptions.filter(item => item !== valObj);
        
        let filterAlreadySet = false
        for (let i = 0; i < this.props.filterList.length; i++) {
            const element = this.props.filterList[i];
            if(element.name === this.props.filterName && element.values === valObj){
                filterAlreadySet = true
            }
        }

        if(filterAlreadySet){
            this.setState({
                chosenValue: null,
                currentText: "",
            });
            return
        } 


        const filterObj = {
            key: key,
            name: this.props.filterName,
            values: valObj,
            displayValue: displayValue
        }

        const newList = addFilter(filterObj, this.props.filterList)

        this.props.dispatch(action_change_filters(newList));

        this.setState({
            chosenValue: null,
            currentText: "",
        });
    }

    render() {
        return (
            <div className={this.props.classes.rootContainer}>
                <p className={this.props.classes.filterText}>{this.props.prefix}</p>
                <hr />
                <div className={this.props.classes.valueContainer}>
                    <Autocomplete
                        disablePortal
                        options={this.state.currentOptions}
                        sx={{ width: "376px" }}
                        value={this.state.chosenValue}
                        onChange={(event, chosenValue) => {
                            this.setState({ chosenValue }, () => {
                                this.handleConfirm(); // Invoke the confirm functionality after setting the chosen value
                            });
                        }}
                        renderInput={(params) => <TextField {...params} label={this.props.prefix} />}
                    />
                </div>
            </div>
        );
    }
}

const styles = ({
    rootContainer: {
        margin: '5px',
        display: "flex",
        marginBottom: "20px",
        flexDirection: 'column',
    },
    filterText: {
        margin: "auto",
        padding: "2px",
    },
    valueContainer: {
        marginTop: "5px",
        display: "flex"
    },
    dateField: {
        width: '170px',
        height: '50px',
        margin: 'auto',
        '& .MuiInputBase-root': {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            marginRight: "10px"
        }
    }
});

function mapStateToProps(state) {
    return {
        filterList: state.filter.filterList,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(TagFilter));

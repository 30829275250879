import React, { Component } from 'react';
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import Search from '@material-ui/icons/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDice } from '@fortawesome/free-solid-svg-icons/faDice';
import ColorFilter from '../FilterSection/ColorFilter';
import EnumFilter from '../FilterSection/EnumFilter';
import RangeFilter from '../FilterSection/RangeFilter';
import DateRangeFilter from '../FilterSection/DateRangeFilter';
import Close from "@material-ui/icons/Close";
import ImageDialog from '../ImageDialog/ImageDialog';

// import Palette from '@material-ui/icons/Palette';

import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';

import {
  action_submit_search_term,
  action_upload_image,
  action_show_similar_images,
  // action_similar_images,
  action_filter_color,
  // action_filter_license,
  action_filter_color_processing
} from '../../../Modules/redux/actions';

import SimilarImagesBox from '../SimilarImagesBox/SimilarImagesBox';
import Collapse from '@material-ui/core/Collapse';
import withStyles from "@material-ui/core/styles/withStyles";
import getEventBus from "../../../lib/ImageSphereEventBus";
import FilterDisplay from '../FilterSection/FilterDisplay';
// import LicenseFilter from '../FilterSection/LicenseFilter';
import { resizeImage } from "../../../API/jsonAPI";
import MyPaper from "../../Styled/MyPaper";
import MyTooltip from "../../Styled/MyTooltip";
import styleTheme from "../../../styleTheme";
import TagFilter from '../FilterSection/TagFilter';

const mainColor = styleTheme.palette.secondary.dark

const Paper = MyPaper;
const Tooltip = MyTooltip;

const toolTipDelay = 1000;


class SearchInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      resizedImg: "",
      showFilters: false
    };

    this.search = this.search.bind(this);
    this.imgUpload = this.imgUpload.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.resetText = this.resetText.bind(this)
    this.randomSearch = this.randomSearch.bind(this);

    window.SearchInput = this;
    this.lastRandomSearch = 0;
    this.eventBus = getEventBus();

    this.colorFilterRef = React.createRef();
    this.licenseFilterRef = React.createRef();
  }

  componentDidMount = () => {
    const self = this;
    this.imageClickedId = this.eventBus.subscribe('ImageClicked', function (obj) {
      if(obj.mode === "double"){
        self.fileUpload.value = "";
        self.setState({ resizedImg: "", showFilters: false })
        self.resetText();
        
      }else{
        self.setState({showFilters: false })
      }
      
    });
  };

  componentWillUnmount = () => {
    this.eventBus.unsubscribe('ImageClicked', this.imageClickedId);
  };

  async imgUpload(event) {

    if (event.target.files[0] !== undefined) {

      this.props.dispatch(action_filter_color_processing(true));
      this.props.dispatch(action_show_similar_images(true));
      this.props.dispatch(action_upload_image(event.target.files[0]));
      this.props.dispatch(action_submit_search_term(null));
      this.props.dispatch(action_filter_color([]));

      this.resetText();

      // this.closeColorAndLicenseDialog();
      const resizedImg = await resizeImage(event.target.files[0], 40, true);
      this.setState({ resizedImg: resizedImg });
      this.fileUpload.value = "";
      this.props.dispatch(action_filter_color_processing(false));
    }
  }

  /**
   * @override
   */
  // componentDidMount() {
  //   this.imageClickedId = this.eventBus.subscribe('ImageClicked', () => {
  //     this.closeColorAndLicenseDialog();
  //   });
  // }

  /**
   * @override
   */
  componentWillUnmount() {
    this.eventBus.unsubscribe(this.imageClickedId);
  }

  search(event, searchString) {

    // enter was pressed
    if ((event.keyCode === 13) || (event.type === 'click')) {
      event.preventDefault()
      this.props.dispatch(action_show_similar_images(true));
      this.props.dispatch(action_submit_search_term(searchString));
      this.setState({ resizedImg: "", showFilters: false })
      if(window.CanvasContainer.props.search.searchTerm === searchString){
        window.CanvasContainer.textSearch(searchString)
      }
    }
  }



  randomSearch() {
    if (Date.now() - this.lastRandomSearch > 1000) {
      this.lastRandomSearch = Date.now();
      // this.handleReset();

      this.fileUpload.value = "";
      this.setState({ resizedImg: "", showFilters: false })

      this.eventBus.publish('FiltersOpened', true);

      window.CanvasContainer.randomizeViewport(); // TODO should be an action
    }
  }

  // toggleColorPicker() {
  //   if(this.colorFilterRef.current.state.open === false)
  //     this.colorFilterRef.current.openDialog();
  //   else
  //     this.colorFilterRef.current.closeDialog();
  //   this.licenseFilterRef.current.closeDialog();
  // };

  // toggleLicenseFilter() {
  //   if(this.licenseFilterRef.current.state.open === false)
  //     this.licenseFilterRef.current.openDialog();
  //   else
  //     this.licenseFilterRef.current.closeDialog();
  //   this.colorFilterRef.current.closeDialog();
  // };

  // closeColorAndLicenseDialog() {
  //   this.colorFilterRef.current.closeDialog();
  //   this.licenseFilterRef.current.closeDialog();
  // }'

  handleReset = () => {
    this.fileUpload.value = ""
    this.props.resetSearch()
  };

  handleDeleteTag = item => () => {
    let searchTermWithoutClickedText = this.props.searchTerm.replace(item, '');

    //strip new searchterm and test if empty
    if (searchTermWithoutClickedText.replace(/^\s+|\s+$/g, '') === '') searchTermWithoutClickedText = null;

    //const lastItemInArray = (searchTermArray.length === 1) && (searchTermArray[0] === searchTermWithoutClickedText);

    if (searchTermWithoutClickedText === null && (this.props.nSelectedLicenses + this.props.nSelectedColors === 0)) {
      this.handleReset();
    } else {
      this.props.dispatch(action_submit_search_term(searchTermWithoutClickedText));
    }
  };

  resetText() {
    this.props.setSearchText("")
  }

  renderFilterComponents() {

    if (!this.props.datasets[this.props.chosenGraph]) return (<div></div>)
    const renderList = []
    const chosenDatasetFilters = this.props.datasets[this.props.chosenGraph].filters
    chosenDatasetFilters.forEach(filter => {
      let filterComponent = null;
      switch (filter.type) {
        case "Enum":

          const displayValues = filter.choices.map((e) => e.displayText)
          const choices = filter.choices.map((e) => e.name)
          filterComponent = <EnumFilter key={filter.name} displayValues={displayValues} attributes={choices} filterName={filter.name} prefix={filter.prefix} suffix={filter.suffix} />
          break;

        case "Boolean":
          filterComponent = <EnumFilter key={filter.name} displayValues={[filter.prefix]} filterName={filter.name} prefix={filter.prefix} suffix={filter.suffix} />
          break;
        case "Number":
          filterComponent = <RangeFilter key={filter.name} filterName={filter.name} prefix={filter.prefix} suffix={filter.suffix} />
          break;
        case "Datetime":
          filterComponent = <DateRangeFilter key={filter.name} filterName={filter.name} prefix={filter.prefix} suffix={filter.suffix} />
          break;
        case "Tag":
          filterComponent = <TagFilter key={filter.name} filterName={filter.name} prefix={filter.prefix} suffix={filter.suffix} />
          break;
        default:
          break;
      }
      renderList.push(filterComponent)
    });
    return (renderList)

  }

  renderFilterOptions() {

    if (this.props.datasets[this.props.chosenGraph] && this.props.datasets[this.props.chosenGraph].filters.length > 0) {

      let filterToggleIcon = <ArrowUpIcon />

      if (!this.state.showFilters) {
        filterToggleIcon = <ArrowDownIcon />

      }
      return (
        <div>
          <div className={this.props.classes.filterTogglePanel}>
            <p className={this.props.classes.filterText}>Filters</p>
            <IconButton
              edge="end"
              aria-label="Show Filters"
              className={this.props.classes.filterToggleButton}
              color="secondary"
              onClick={() => {
                this.eventBus.publish('FiltersOpened', true);
                this.setState({ showFilters: !this.state.showFilters })
              }
              }>
              {filterToggleIcon}
            </IconButton>
          </div>
          <Collapse in={this.state.showFilters}>
            <div className={this.props.classes.filterContainer}>
              <ColorFilter />
              {this.renderFilterComponents()}
            </div>
          </Collapse>
        </div >
      )
    }
    else {
      return (<div></div>)
    }

  }

  render() {


    let searchButton;
    if (this.props.searchText.length > 0) {
      searchButton =
        <Tooltip title="Search" enterDelay={toolTipDelay}>
          <IconButton
            edge="end"
            aria-label="Search"
            className={this.props.classes.iconButton}
            onClick={(e) => this.search(e, this.props.searchText)}>
            <Search />
          </IconButton>
        </Tooltip>
    } else {
      searchButton =
        <Tooltip title="Random search" enterDelay={toolTipDelay}>
          <IconButton
            edge="end"
            aria-label="Search"
            className={this.props.classes.iconButton}
            onClick={this.randomSearch}>
            <FontAwesomeIcon icon={faDice} />
          </IconButton>
        </Tooltip>
    }
    let deleteTextHTML
    if (this.props.searchText === "") {
      deleteTextHTML = (<div></div>)
    }
    else {
      deleteTextHTML = (
        <IconButton style={{ marginLeft: "-45px", marginBottom: "-25px" }} onClick={this.resetText}>
          <Close />
        </IconButton>
      )
    }
    return (

      <div className={this.props.classes.rootContainer}>

        <Paper>
          <Box className={this.props.classes.drawerPaper + " " + this.props.classes.root}>



            <Tooltip title="Upload image" enterDelay={toolTipDelay}>
              <IconButton
                edge="end"
                aria-label="Add a photo"
                className={this.props.classes.iconButton}
                color="secondary"
                onClick={() => this.fileUpload.click()}>
                <AddAPhoto />
              </IconButton>
            </Tooltip>

            {searchButton}

            <TextField
              className={this.props.classes.textInput}
              id="standard-basic"
              label="Text Search Input"
              variant="standard"
              multiline
              maxRows={4}
              value={this.props.searchText}
              onChange={(e) => this.props.setSearchText(e.target.value)}
              color="primary"
              onKeyDown={(e) => this.search(e, this.props.searchText)}
              InputLabelProps={{
                style: { fontSize: 20 },
              }}
            />
            {deleteTextHTML}
            {/* <div className={this.props.classes.searchDivider}/> */}

            {/* <div style={{marginLeft: "auto", minWidth: "70px"}}>
            <Tooltip title="Search by color" enterDelay={toolTipDelay}>
              <IconButton
                edge="end"
                aria-label="Filter Color"
                className={this.props.classes.iconButton}
                onClick={() => this.toggleColorPicker()}>
                <Palette/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Filter by license" enterDelay={toolTipDelay}>
              <IconButton
                edge="end"
                aria-label="Filter License"
                className={this.props.classes.iconButton}
                onClick={() => this.toggleLicenseFilter()}>
                <svg aria-hidden="true" focusable="false"
                     role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox={ccIcon.viewBox} className={this.props.classes.licenseButton}>
                  <path fill="currentColor" d={ccIcon.path}/>
                </svg>
              </IconButton>
            </Tooltip>
            </div> */}


            <input
              type="file"
              accept="image/*"
              id="contained-button-file"
              hidden={true}
              onChange={(e) => this.imgUpload(e)}
              ref={(fileUpload) => {
                this.fileUpload = fileUpload;
              }} />
          </Box>
          <FilterDisplay resizedImg={this.state.resizedImg} resetSearch={() => this.handleReset()} />

          {this.renderFilterOptions()}

          <ImageDialog />
          {/* <Collapse in={this.props.showSimilarImages || this.props.searchTerm !== ""}> */}
          <SimilarImagesBox isOpen={this.props.showSimilarImages || this.props.searchTerm !== ""} />
          {/* </Collapse> */}




          {/* <ColorFilter ref={this.colorFilterRef} /> */}
          {/* <LicenseFilter ref={this.licenseFilterRef} /> */}

          {/* <hr/>
          <SuggestedTags /> */}
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    searchTerm: state.search.searchTerm,
    chosenGraph: state.settings.chosenGraph,
    datasets: state.settings.availableDatasets,
    nSelectedColors: state.filter.color.length,
    nSelectedLicenses: state.filter.licenses.length,
    uploaded_img: state.search.uploaded_img,
    showSimilarImages: state.search.showSimilarImages
  }
}

const styles = () => ({
  root: {
    padding: '2px',
    display: 'flex',
    flexDirection: 'row',
  },
  filterTogglePanel: {
    paddingLeft: '5px',
    marginTop: "5px",
    display: 'flex',
    flexDirection: 'row',
    width: "100%"
  },
  filterToggleButton: {
    // padding: 20,
    marginRight: "0px",
    width: "45px",
    color: mainColor,
    // marginBottom: -30
  },
  filterText: {
    fontSize: 20,
    width: "30%",
    margin: "2% 0% 0 2%",
    color: mainColor,
    marginRight: "auto"
  },
  textInput: {
    width: "78%",
    margin: "auto",
    marginTop: "10px",
    border: "none",
    fontSize: "16px",
    '& .MuiInputBase-input': {
      fontSize: 20,
      lineHeight: 1.2
    }
  },
  input: {
    flex: 1,
    "& > input": {
      padding: 0,
      marginTop: "10px",
      marginLeft: "10px",
      fontSize: "16px"
    }
  },
  iconButton: {
    padding: 10,
    width: "45px",
    color: mainColor,
    marginBottom: -30
  },
  rootContainer: {
    padding: "10px 0 0 0"
  },
  searchDivider: {
    width: "2px",
    margin: "4px 0 0 13px",
    backgroundColor: "#ddd",
  },
  licenseButton: {
    width: '20px',
    height: '20px',
  }
});


export default connect(mapStateToProps)(withStyles(styles)(SearchInput));

import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Alert from '@mui/material/Alert';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import { feedback } from '../../../API/jsonAPI';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class FeedbackDialog extends React.Component {

  constructor(props) {
    super(props);
  
    this.state = {
      open: false,
      reason: "General",
      alertOpen: false,
      errorAlertOpen: false,
      showSendButton: true
    };
  
    window.FeedbackDialog = this;
    this.reasonRef = React.createRef();
    this.emailRef = React.createRef();
    this.feedbackRef = React.createRef();
  }

  handleClickOpen() {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleAlertClose = () => {
    this.setState({ alertOpen: false });
    // Close the dialog
    this.handleClose();
  };

  handleErrorAlertClose = () => {
    this.setState({ errorAlertOpen: false });
  }

  handleSend = () => {
    
    if(this.feedbackRef.current.value !== ""){
      feedback(this.reasonRef.current.value, this.emailRef.current.value, this.feedbackRef.current.value);
      this.setState({ alertOpen: true, showSendButton: false });

    }else{
      this.setState({ errorAlertOpen: true });
    }

  
    
  
    
  };

  // outlined selects in dialogs with working label updates are a problem
  // https://github.com/mui-org/material-ui/issues/14203
  // https://codesandbox.io/s/material-demo-03c1m
  // https://codesandbox.io/s/material-demo-ib2dt
  handleChange = (event) => {
    this.setState({
      reason: event.target.value
    });
  };

  render() {

    const feedback =
      <DialogContent className={this.props.classes.dialogContent}>

        <h1>Feedback</h1>
        Share your ideas for improvements. In case of problems please
        describe your issue and leave your email address if you wish
        to be contacted. If you like navigu please let other people know.

          <FormControl className={this.props.classes.formControl}>

            <Select
              value={this.state.reason}
              onChange={this.handleChange}
              input={<OutlinedInput />}
              inputRef={this.reasonRef}
              className={this.props.classes.dialogInput}
            >
              <MenuItem value={"General"}>General</MenuItem>
              <MenuItem value={"Improvement"}>Improvement</MenuItem>
              <MenuItem value={"Issue"}>Issue</MenuItem>
            </Select>

            <TextField
              label="E-Mail Address (optional)"
              defaultValue=""
              margin="normal"
              variant="outlined"
              inputRef={this.emailRef}
              className={this.props.classes.dialogInput}
            />

            <TextField
              label="Please let us know how you like navigu."
              multiline
              minRows="5"
              maxRows="5"
              required={true}
              defaultValue=""
              margin="normal"
              variant="outlined"
              inputRef={this.feedbackRef}
              className={this.props.classes.dialogInput}
            />
          </FormControl>
      </DialogContent>;

    return (
      <Dialog
        open={!!this.state.open}
        onClose={this.handleClose}
        className={this.props.classes.dialog}
        maxWidth="md"
        fullWidth={false}
        TransitionComponent={Transition}>
        {feedback}
        {this.state.alertOpen && (
            <Alert 
              onClose={this.handleAlertClose} 
              severity="success" 
              icon={<CheckIcon fontSize="inherit" />}>
              Thank you for your feedback!
            </Alert>
        )}
        {this.state.errorAlertOpen && (
            <Alert 
              onClose={this.handleErrorAlertClose} 
              severity="error" 
              icon={<ErrorIcon fontSize="inherit" />}>
              You can not send an empty feedback message.
            </Alert>
        )}
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            close
          </Button>
          {this.state.showSendButton && (
            <Button onClick={this.handleSend} color="secondary">
              send
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialog: {
    zIndex: 2500,
    fontSize: 14,
    userSelect: "text",
    
  },
  dialogContent: {
    paddingBottom: 22,
    height: "100%",
    display: 'flex',
    maxWidth: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'justify',
    overflowX: "hidden"
  },
  formControl: {
    width: "100%",
    margin: theme.spacing(1),
    minWidth: 120,
  },
  dialogInput:{
    minHeight: "45px"
  }

});

export default withStyles(styles)(FeedbackDialog);

import { IMAGE_BASE_URL, DELETED_IMAGE_URL } from '../../../config'
import { createGraphURLPart } from "../../../API/jsonAPI"
import store from '../../../Modules/redux/store';

export default class DataPreprocessor {

  startProcessingPipeline(data, gridSideLength) {
    const sortedTiles = this.sortTilesFromCenter(data.tiles, gridSideLength);
    return this.generateImageArray2D(sortedTiles, gridSideLength);
  }

  sortTilesFromCenter(tiles, gridSideLength) {
    const center = gridSideLength / 2;
    return tiles.sort((a, b) => {
      if (a === null || b === null) {
        return 0
      }
      const distA = Math.sqrt(Math.pow(a.x - center, 2) + Math.pow(a.y - center, 2));
      const distB = Math.sqrt(Math.pow(b.x - center, 2) + Math.pow(b.y - center, 2));
      return distA - distB
    });
  }

  generateImageArray2D(tiles, gridSideLength) {
    let imageArray2D = [];

    const graphURL = createGraphURLPart()
    const imageType = store.getState().settings.selectedImageType

    // console.log(imageType)

    let imageTypeString = ""
    switch (parseInt(imageType)) {
      case 1:
        imageTypeString = "/crop"
        break;
      case 2:
        imageTypeString = "/stretch"
        break;
      default:
        break;
    }
    // console.log(imageType, imageTypeString)
    tiles.forEach(async (tile, tileIndex) => {
      if (!imageArray2D[tile.y]) {
        imageArray2D[tile.y] = [];
      }
      let imageData = tile.content ? Object.assign({}, tile.content) : null;
      imageArray2D[tile.y][tile.x] = this.createImageElement(graphURL, imageData, imageTypeString);
    });

    let imageArray2DT = new Array(gridSideLength);

    for (let y = 0; y < imageArray2D.length; y++) {
      imageArray2DT[y] = new Array(gridSideLength);
      for (let x = 0; x < imageArray2D[y].length; x++) {
        const tx = x;
        const ty = y;

        if (imageArray2D[ty] && imageArray2D[ty][tx]) {
          imageArray2DT[y][x] = imageArray2D[ty][tx];
        } else {
          imageArray2DT[y][x] = 'preview';
        }

      }

    }

    return imageArray2DT

  }

  onLoadHandler(event) {
    event.target.loaded = true;
  }

  createImageElement(graphURL, imageData, imageTypeString) {


    if (imageData) {
      let image = new Image();
      image.loaded = false;
    
    image.src = imageData.id === -1 ? DELETED_IMAGE_URL : `${IMAGE_BASE_URL}/${graphURL}/thumbs${imageTypeString}/${imageData.path}`
    image.id = imageData.id;

    //replace png to jpg
    image.src = image.src.split('.').slice(0, -1).join('.') + ".jpg"

    image.onload = this.onLoadHandler;

    image.width = 256;
    image.height = 256;
    image.setAttribute("name", imageData.path);
    return image;
  }
}
}

import React from 'react';

import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styleTheme from './styleTheme.js';

import clsx from "clsx";

import { Provider } from 'react-redux';
import store from './Modules/redux/store';

import SideBarContent from "./Components/SideBarContent/SideBarContent";
import MainViewContent from "./Components/MainViewContent/MainViewContent";
import ErrorBoundary from "./ErrorBoundary";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';



let drawerWidth = window.innerWidth > 1000 ? 400 : 320;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        };

        //create sessionID
        window.sessionID = Math.random().toString(16).slice(7);
        window.app = this;
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
        window.canvas.hideCenterImageButton(true);
        setTimeout(() => {
            window.canvas.redrawCanvas();
        }, 250);

    };

    handleDrawerClose = () => {
        this.setState({ open: false });
        window.canvas.hideCenterImageButton(true);
        setTimeout(() => {
            window.canvas.redrawCanvas();
        }, 250);
    };



    render() {
        // // if( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        //     if(false){
        //     return (
        //       <div className={this.props.classes.mobileOverlay}>
        //           <h3>Sorry, but we have not optimized this application for smaller devices yet</h3>
        //           <h5>Please use a desktop computer with a modern web browser</h5>
        //       </div>
        //     );
        // } else {
        return (
            <Provider store={store}>
                <MuiThemeProvider theme={styleTheme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <ErrorBoundary>
                            <div className={this.props.classes.root}>
                                <div style={{ marginTop: '5px', width: '25px', height: '0px', zIndex: '1000' }}>
                                    <Paper square={true} elevation={0} style={{ borderRadius: '0px 5px 5px 0px' }}>
                                        <IconButton
                                            style={{
                                                outline: 'none',
                                                backgroundColor: 'transparent',
                                                paddingLeft: '1px',
                                                paddingRight: '0px',
                                                zOrder: "10"
                                            }}
                                            onClick={this.handleDrawerOpen}>
                                            <ChevronRightIcon />
                                        </IconButton>
                                    </Paper>
                                </div>
                                <Drawer
                                    id="drawer"
                                    className={this.props.classes.drawer}
                                    variant="persistent"
                                    anchor="left"
                                    open={this.state.open}
                                    style={{ overflowY: 'visible' }}
                                    classes={{ paper: this.props.classes.drawerPaper }}>

                                    <SideBarContent theme={this.state.theme} />
                                    <div style={{ position: 'absolute', left: '100%', top: '8px', width: '25px' }}>
                                        <Paper square={true} elevation={0} style={{ borderRadius: '0px 5px 5px 0px' }}>
                                            <IconButton
                                                style={{
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    paddingLeft: '1px',
                                                    paddingRight: '0px'
                                                }}
                                                onClick={this.handleDrawerClose}>
                                                <ChevronLeftIcon />
                                            </IconButton>
                                        </Paper>
                                    </div>
                                </Drawer>
                                <main id="main" className={clsx(this.props.classes.content, {
                                    [this.props.classes.contentShift]: this.state.open,
                                })}
                                    style={{ width: "100%", backgroundColor: styleTheme.palette.background.main }}>
                                    <MainViewContent theme={this.state.theme} />
                                </main>
                            </div>
                        </ErrorBoundary>
                    </LocalizationProvider>
                </MuiThemeProvider>
            </Provider>

        );
    }
    // }
}

const styles = theme => ({
    root: {
        display: 'flex',
        height: '100%',
        alignContent: 'center',
        backgroundColor: 'rgb(220, 220, 220)'
    },
    menuButton: {
        height: '65px',
        width: '65px',
        margin: '10px'
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        height: '0px',
        flexShrink: 0,

    },
    drawerPaper: {
        width: drawerWidth,
        overflow: 'visible',
        border: "none"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth - 25,
        overflow: "hidden"
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: -25,
        overflow: "hidden",
    },

    mobileOverlay: {
        alignItems: "center",
        background: "#fff",
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        left: 0,
        padding: "30px",
        position: "fixed",
        right: 0,
        textAlign: "center",
        top: 0,
        zIndex: 9999
    }
});

export default withStyles(styles)(App);

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import styleTheme from '../../../styleTheme.js';
import MyPaper from "../../Styled/MyPaper";
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import {action_change_graph, action_similar_images} from '../../../Modules/redux/actions';
import {connect} from "react-redux";

const Paper = MyPaper;

const mainColor = styleTheme.palette.secondary.dark
class SetSelector extends React.Component {

  constructor(props) {
    super(props);
    this.setImageSet = this.setImageSet.bind(this);
  }
 
  setImageSet(e){

    const datasetNames = this.props.datasets.map((e) => e.displayName)
    const value = datasetNames.indexOf(e.target.value.split(" (")[0])
    
    // window.history.pushState({hash: ""}, "", window.location.pathname
    // + window.location.search);

    this.props.dispatch(action_change_graph(Object.assign(value, {})))
    this.props.dispatch(action_similar_images([]));
    // console.log("change", this.props.chosenGraph, value, e.target.value, e.target.value.split(" (")[0])
    this.props.resetSearch()
  }

  render() {

    if (this.props.chosenGraph === -1) return (<div></div>)

    const options = []
    for (let i = 0; i <  this.props.datasets.length; i++) {

      //only visible datasets are shown in the live version
      if (!this.props.datasets[i].visible) continue

      const displayName = this.props.datasets[i].displayName;
      const size = this.props.datasets[i].size;
      options.push(<option key={i} >{`${displayName} (${size})`}</option>)
    }

    const size = this.props.datasets[this.props.chosenGraph].size;
    const displayName = this.props.datasets[this.props.chosenGraph].displayName
    const value = `${displayName} (${size})`
    return (
      <div className={this.props.classes.rootContainer}>

        <Paper className={this.props.classes.paper}>
          <p className={this.props.classes.selectText}>Select Image Set</p>
          <FormControl className={this.props.classes.formControl}>
            {/* <InputLabel style={{ fontSize: "20px" }} htmlFor="grouped-native-select">Select Image Set</InputLabel> */}
            <NativeSelect onChange={this.setImageSet}
              color="secondary"
              value={value}
              style={{ fontSize: "20px", color: mainColor }}
              variant="standard"
              disableUnderline={true}
              id="grouped-native-select">
              {/* <option aria-label="None" value="" /> */}
              {options}
            </NativeSelect>
          </FormControl>
        </Paper>
      </div>

    );
  }
}

const styles = ({
  rootContainer: {
    padding: "10px 0 0 0",
  },
  formControl: {
    width: '80%',
    fontSize: "32px",
    margin: "0 0 0 0%",
  },
  paper: {
    display: "flex"
  },
  selectText:{
    fontSize: 12,
    width: "15%",
    margin: "2% 0% 0 2%",
  }
});

function mapStateToProps(state) {
  return {
    chosenGraph: state.settings.chosenGraph,
    datasets: state.settings.availableDatasets
  }
}

export default connect(mapStateToProps)(withStyles(styles)(SetSelector));

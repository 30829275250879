import store from '../Modules/redux/store.js'

export default class JSONGenerator {

  getSearchObject(count = 6) {
    return {
      keywords: store.getState().search.searchTerm ? store.getState().search.searchTerm.split(" ") : [],
      colors: store.getState().filter.color.map(item => item.slice(1)), // remove # in front of every color
      count
    }
  }

  /**
   * 
   * TODO this will be replaced to generate an object from all active filters in the state
   */
  getLicenseFilterObject() {
    const obj = {};
    const arr = ["cc", "by", "sa", "pd", "other"];
    arr.forEach((license) => {
      const searchTerm = license === "other" ? "ot" : license;
      obj[license] = store.getState().filter.licenses.includes(searchTerm) ? 0 : 1
    });

    return obj;
  }

  getFilterObject() {
    const filterList = store.getState().filter.filterList

    const finalFilterObject = {}

    for (let i = 0; i < filterList.length; i++) {
      const filter = filterList[i];

      if (filter.name in finalFilterObject) {
        if (Array.isArray(finalFilterObject[filter.name]))
          finalFilterObject[filter.name].push(filter.values)
        else
          finalFilterObject[filter.name] = [finalFilterObject[filter.name], filter.values]
      } else {
        finalFilterObject[filter.name] = filter.values
      }

    }

    //add colors to filter object

    finalFilterObject["colors"] = store.getState().filter.color.map(value => value.substring(1));

    return finalFilterObject
  }
}
import React from 'react';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faTh, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { MAX_LOCAL_MAP_SIZE, MIN_LOCAL_MAP_SIZE } from '../../../config';
import { grid2x2, grid3x3 } from "../../../lib/SettingIcons";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import styleTheme from '../../../styleTheme';

import {
  action_shape_value,
  action_background_color,
  action_gridside_length,
  action_set_image_Type
} from '../../../Modules/redux/actions';

class SettingsSliderContainer extends React.Component {

  constructor(props) {
    super(props);
    window.Slider = this;
  }

  

  handleShapeValueChange(event, value) {
    window.localStorage.setItem("naviguImageSphereShapeValue", value);
    this.props.dispatch(action_shape_value(value));
  };

  handleBackgroundColorChange(event, value) {
    window.localStorage.setItem("naviguImageSphereBackgroundColor", value);
    this.props.dispatch(action_background_color(value));
  };

  handleGridSideLengthChange(event, value) {
    window.localStorage.setItem("naviguImageSphereGridSideLength", value + 1);
    this.props.dispatch(action_gridside_length(value + 1));
  }

  handleComboBoxChange(event) {
    window.localStorage.setItem("naviguImageSphereImageType", parseInt(event.target.value));
    this.props.dispatch(action_set_image_Type(event.target.value));
  }

  // https://github.com/mui-org/material-ui/issues/10845#issuecomment-377367600
  render() {
    return (
      <div>
        <div className={this.props.classes.wrapper}>
          <Typography id="Shape Value" className={this.props.classes.typography}> <FontAwesomeIcon icon={faTh} />  </Typography>
          <PrettoSlider
            value={parseInt(this.props.shapeValue)}
            aria-labelledby="Shape Value"
            onChange={this.handleShapeValueChange.bind(this)}
            min={0} max={100} step={5}
            height='15px'
            color='secondary'
          />
          <Typography id="Shape Value" className={this.props.classes.typography}> <FontAwesomeIcon icon={faGlobe} /> </Typography>
        </div>
        <div className={this.props.classes.wrapper}>
          <Typography id="Grid Size" className={this.props.classes.typography}> <FontAwesomeIcon icon={grid2x2} />  </Typography>
          <PrettoSlider
            value={parseInt(this.props.gridSideLength)}
            aria-labelledby="Grid Size"
            onChange={this.handleGridSideLengthChange.bind(this)}
            min={MIN_LOCAL_MAP_SIZE - 1} max={MAX_LOCAL_MAP_SIZE - 1} step={2}
            color='secondary'
          />
          <Typography id="Grid Size" className={this.props.classes.typography}>  <FontAwesomeIcon icon={grid3x3} /> </Typography>
        </div>
        <div className={this.props.classes.wrapper}>
          <Typography id="Background Color" className={this.props.classes.typography}> <FontAwesomeIcon icon={faMoon} />  </Typography>
          <PrettoSlider
            value={parseInt(this.props.backgroundColor)}
            aria-labelledby="Background Color"
            onChange={this.handleBackgroundColorChange.bind(this)}
            min={0} max={240} step={5}
            color='secondary'
          />
          <Typography id="Background Color" className={this.props.classes.typography}>  <FontAwesomeIcon icon={faSun} /> </Typography>
        </div>
        <div style={{display:"flex", width:"110%"}}>

          <FormControl>
            {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
            <RadioGroup
              style={{marginLeft:"10px", marginRight:"5px"}}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={this.handleComboBoxChange.bind(this)}
              value={this.props.selectedImageType}
              defaultValue={3}
            >
              <FormControlLabel value={1} control={<Radio size="small"
                sx={{
                  color:styleTheme.palette.secondary.main,
                  '&.Mui-checked': {
                    color: styleTheme.palette.secondary.main,
                  },
                }} />} label={
                  <Typography style={{fontSize:"9px",  marginLeft: "-8px"}}>Crop</Typography>
                } />
              <FormControlLabel value={2} control={<Radio size="small"
                sx={{
                  color:styleTheme.palette.secondary.main,
                  '&.Mui-checked': {
                    color:styleTheme.palette.secondary.main,
                  },
                }} />} label={
                  <Typography style={{fontSize:"9px",  marginLeft: "-8px"}}>Square</Typography>
                } />
              <FormControlLabel value={3} control={<Radio size="small"
                sx={{
                  color:styleTheme.palette.secondary.main,
                  '&.Mui-checked': {
                    color:styleTheme.palette.secondary.main,

                  },
                }} />} label={
                  <Typography style={{fontSize:"9px", marginLeft: "-8px"}}>Border</Typography>
                } />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    shapeValue: state.settings.shapeValue,
    backgroundColor: state.settings.backgroundColor,
    gridSideLength: state.settings.gridSideLength,
    selectedOption: state.settings.selectedOption,
    selectedImageType: state.settings.selectedImageType
  };
}

// https://material-ui.com/styles/basics/
const PrettoSlider = withStyles({
  root: {
    height: 8,
    marginRight: '5px',
    marginLeft: '10px'
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -5,
    marginLeft: -9,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 6,
    borderRadius: 3,
  },
  rail: {
    height: 6,
    borderRadius: 3,
  },
})(Slider);

const styles = () => ({
  wrapper: {
    width: '95%',
    display: 'flex'
  },
  typography: {
    width: '10%',
    marginLeft: 10,
    marginRight: 3,
    lineHeight: 2,
    display: "flex",
    alignItems: "center",
    fontSize: "17px"
  }
});

export default connect(mapStateToProps)(withStyles(styles)(SettingsSliderContainer));

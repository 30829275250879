import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import Help from '@material-ui/icons/Help';
import Feedback from '@material-ui/icons/Feedback';
import IconButton from '@material-ui/core/IconButton';
import styleTheme from '../../../styleTheme.js';

const backgroundColor = styleTheme.palette.secondary.main
class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dark: false,
      settings: false
    };
  }

  feedback() {
    window.FeedbackDialog.handleClickOpen();
  }

  help() {
    window.HelpDialog.handleClickOpen();
  }

  reloadPage() {
    // window.location.reload();
    window.location.href = "/"
  }

  render() {
    return (
      <div className={this.props.classes.header}>

        <div className={this.props.classes.linksContainer}  onClick={this.reloadPage}>
          <div style={{display: "flex" }}>
            <div className={this.props.classes.logoContainer}>
              <p className={this.props.classes.nameText}>N</p>
              <img alt="" src="arrow.svg" 
              style={{ width: "27px", height: "27px", margin: '12px -4px 0px -1px', zIndex: 9, position: 'relative' }}
               />
              <p className={this.props.classes.nameText}>VIGU</p>
            </div>
          </div>
          <div style={{ marginTop: "-24px", marginRight: "-27px", display: "flex" }}>
            <p className={this.props.classes.additionalNameText}>Navigating A Visual Image Graph in a User-friendly way</p>
          </div>
        </div>
        <div className={this.props.classes.headerContainer}>

          {/* {/* <IconButton
                edge="end"
                aria-label="Info"
                className={this.props.classes.headerIcon}
                onClick={this.help}
              >
                <Help/> 
              </IconButton> */}

          <IconButton
            edge="end"
            aria-label="Help"
            className={this.props.classes.headerIcon}
            onClick={this.feedback}
          >
            <Feedback />
          </IconButton>

        </div>
      </div>

    );
  }
}

const styles = ({
  headerIcon: {
    zIndex: 2,
    color: "#fff",
    backgroundColor: backgroundColor,
    "& > span > svg": {
      fontSize: "1.5rem",
    },
  },
  logoContainer: {
    display: 'flex',
    marginLeft: "auto",
    marginRight: "auto"
  },
  headerContainer: {
    marginLeft: 'auto',
    marginRight: "10px"
  },
  header: {
    display: 'flex',
    background: backgroundColor,
    width: "100%",
    height: "60px",
    minHeight: "60px",
    textAlign: "center",
    verticalAlign: 'middle',
    padding: "2px",
  },
  nameText: {
    color: "#fff !important",
    background: backgroundColor,
    fontSize: "35px",
    textDecoration: 'none'
  },
  additionalNameText: {
    color: "#fff !important",
    background: backgroundColor,
    fontSize: "12px",
    margin: "auto"
  },
  linksContainer: {
    width: "360px",
    marginTop: "-5px",
    cursor: "pointer",
  }
});

export default withStyles(styles)(Header);

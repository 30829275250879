import React from 'react';
import SettingsSliderContainer from "../Settings/SettingsSliderContainer";
import LevelIndicator from "./LevelIndicator";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Divider from "@material-ui/core/Divider";
import {connect} from "react-redux";
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';


import Settings from '@material-ui/icons/Settings';

const styles = theme => ({
    mainContainer: {
        width: '190px',
        height: '320px',
        margin: "0px",
        opacity: 1,
        background: "#ffffff",
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        zIndex: 1500,
    },
    smallContainer: {
        width: '50px',
        height: '150px',
        margin: "5px 0",
        opacity: 1,
        background: "#ffffff",
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        zIndex: 1500,
        display: "flex",
        flexDirection: "column"
    },
    pyramidContainer: {
        width:'100%',
        // margin:'auto',
        height:"165px",
        marginBottom: '0px'
    },
    settingsContainer: {
        margin:'auto',
        marginBottom: '10px'
    },
    smallSettingsContainer: {
        opacity: 1,
        background: "#ffffff",
        width: "190px",
        height: '180px',
        paddingTop: "0px"
    },
    smallZoomButtonContainer:{
        margin: "auto",
        width: "32px"
    },
    zoomButtonContainer:{
        position: 'absolute',
        top: '10px',
        right: '10px',
        height: '64px',
        width: '30px',

    },
    zoomButton: {
        width: '100%',
        minWidth: '0px',
        height: '30px',
        margin: '2px',
    },

    smallZoomButton: {
        width: '100%',
        minWidth: '0px',
        height: '30px',
        margin: '2px 0',
    },
    zoomIcon:{
        marginTop: '-8px',
        fontSize: '2.0rem'
    },

    settingsButton:{
        width: '30px',
        minWidth: '0px',
        height: '30px',
        margin: '2px 0',
    }
});

class LevelAndSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isHighestLevel: false,
            open: window.innerHeight > 800,
            smallSliderOpen: false,
            
        };
        this.smallButtonContainer = null
    }

    zoomIn = () => {
        this.zoom(this.props.level - 1);
    };

    zoomOut = () => {
        this.zoom(this.props.level + 1);
    };

    zoom = (level) => {
        const image = window.canvas.getClosestImageToCenter();
        this.props.canvasContainerRef.current.handleZoom(image, level);
    };

    handleWindowSizeChange = (event) => {
        this.setState({ open: window.innerHeight > 800});
    };

    toggleSettingsPopper = () => {
        this.setState({"smallSliderOpen": !this.state.smallSliderOpen})
    };


    /**
     * @override
     */
    componentDidMount(){
        this.testIfHighestLevel(this.props.level, this.props.filterLevelResults);
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    /**
     * @override
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    /**
     * @override
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.level !== this.props.level) 
        || (this.getArrSum(prevProps.filterLevelResults) !== this.getArrSum(this.props.filterLevelResults))
        || prevState.isHighestLevel !== this.state.isHighestLevel){
            this.testIfHighestLevel(this.props.level, this.props.filterLevelResults);
        }
    }

    getArrSum(array) {
        return array.reduce((a,b) => a+b, 0);
    }


    /**
     *
     * @param {number} level
     * @param {array} filterLevelResults
     */
    testIfHighestLevel(level, filterLevelResults){
   
        const currentImagesPerLevel = filterLevelResults;
        let isHighest = false;
       
        if (currentImagesPerLevel.length === 0){
            if(level === this.props.numberOfLayers - 1) isHighest = true
        }else{
            let highestIndex = currentImagesPerLevel.length - 1;
            let nItems = -1;
            for (let index = 0; index < this.props.numberOfLayers; index++) {
                nItems = currentImagesPerLevel[index];
                if (nItems < window.levelResultsThreshold){
                    
                    highestIndex = index;
                    break
                }
            }
            //debugger;
            isHighest = level >= (highestIndex);
        }
        this.setState({
            isHighestLevel: isHighest
        })
    }

    render() {
        return (
            <div>
            <Fade in={this.state.open} timeout={0} style={{opacity: 1}}>
                <div className={this.props.classes.mainContainer} ref={ref => this.smallButtonContainer = ref}>
                    <div className={this.props.classes.zoomButtonContainer}>
                    
                    <Button variant="contained" color="secondary" className={this.props.classes.zoomButton} 
                    onClick={this.zoomOut} 
                    disabled={this.state.isHighestLevel}>
                        <ArrowDropUp fontSize="large" className={this.props.classes.zoomIcon}/>
                    </Button>
                    <Button variant="contained" color="secondary" className={this.props.classes.zoomButton} 
                    onClick={this.zoomIn}
                    disabled={this.props.level === 0}>
                        <ArrowDropDown fontSize="large" className={this.props.classes.zoomIcon}/>
                    </Button>
            
                    </div>
                    <div className={this.props.classes.pyramidContainer}>
                        <LevelIndicator isOpen={this.state.open} zoom={this.zoom}
                        canvasContainerRef={this.props.canvasContainerRef}
                        canvasHeight = {styles().pyramidContainer.height}
                        canvasWidth = {styles().mainContainer.width} 
                        />
                    </div>
                    <Divider variant="middle" style={{ width: '100%', margin: '5px 0px 5px 0px' }} />
                    <div className={this.props.classes.settingsContainer}>
                        <SettingsSliderContainer />
                    </div>
                </div>
            </Fade>
            <Fade in={!this.state.open} timeout={0} style={{opacity: 1}}>
                <div className={this.props.classes.smallContainer}>
                    <div className={this.props.classes.smallZoomButtonContainer} ref={ref => this.smallButtonContainer = ref} >
                        <Button variant="contained" color="secondary" className={this.props.classes.smallZoomButton} 
                        onClick={this.zoomOut} 
                        disabled={this.state.isHighestLevel}>
                            <ArrowDropUp fontSize="large" className={this.props.classes.zoomIcon}/>
                        </Button>
                        <Button variant="contained" color="secondary" className={this.props.classes.smallZoomButton} 
                        onClick={this.zoomIn}
                        disabled={this.props.level === 0}>
                            <ArrowDropDown fontSize="large" className={this.props.classes.zoomIcon}/>
                        </Button>
                        <Button variant="contained" color="secondary" className={this.props.classes.smallZoomButton} 
                        onClick={this.toggleSettingsPopper}>
                            <Settings size='large'/>
                        </Button >
                        <Popper style={{zIndex: 99999}} open={this.state.smallSliderOpen} anchorEl={this.smallButtonContainer} placement="left">
                            <Fade style={{opacity: 1}} timeout={350} in={this.state.smallSliderOpen}>
                                <div className={this.props.classes.smallSettingsContainer}>
                                    <SettingsSliderContainer />
                                </div>
                            </Fade>
                        </Popper>
                    </div>
                    
                    
                </div>
            </Fade>
            </div>
            )
    }
}



function mapStateToProps(state) {

    const layers = state.settings.availableDatasets.map(e=>e.layers)

    let layer = 0
    if (layers)
    layer = Number(layers[state.settings.chosenGraph])
    return {  
      level: state.level.level,
      filterLevelResults: state.filter.filterLevelResults,
      numberOfLayers: layer
    }
  }
  
export default connect(mapStateToProps)(withStyles(styles)(LevelAndSettings));

import React from 'react';
import Button from '@material-ui/core/Button';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/Grid";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Search from '@material-ui/icons/Search';
import GetApp from '@material-ui/icons/GetApp';
import getEventBus from "../../../lib/ImageSphereEventBus";
import ImageDetails from './ImageDetails';
import { connect } from "react-redux";
import MyPaper from "../../Styled/MyPaper";
import styleTheme from '../../../styleTheme.js';

import { IMAGE_BASE_URL, DELETED_IMAGE_URL } from '../../../config'
import { createGraphURLPart } from "../../../API/jsonAPI"

const mainColor = styleTheme.palette.secondary.dark

const Paper = MyPaper;


class ImageDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      image: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.showResults = this.showResults.bind(this);
    this.save = this.save.bind(this);
    this.eventBus = getEventBus()
  }

  /**
   * @override
   */
  componentDidMount = () => {

    const self = this;
    this.imageClickedId = this.eventBus.subscribe('ImageClicked', function (obj) {
      if (obj.mode === "single") {
        const image = obj.image
        if (self.state.image !== image) {
          self.setState({
            image: image,
            isOpen: true
          });
        }
      }
    });

    this.eventBus.subscribe('FiltersOpened', function (areFiltersOpen) {
      self.setState({
        isOpen: !areFiltersOpen //&& self.state.image
      });

    });
  };

  /**
   * @override
   */
  componentWillUnmount = () => {
    if (this.eventBus)
      this.eventBus.unsubscribe('ImageClicked', this.imageClickedId);
    this.eventBus.unsubscribe('FiltersOpened', this.imageClickedId);
  };

  /**
   * override
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searchTerm !== prevProps.searchTerm)
      this.setState({ isOpen: false });

    if (this.props.showSimilarImages !== prevProps.showSimilarImages)
      this.setState({ isOpen: false });

    if (this.props.chosenGraph !== prevProps.chosenGraph)
      this.setState({ image: null });
  }

  findSimilar = async () => {
    if (!window.Animator.animationRunning) {
      this.handleClose();
      const image = document.getElementById("ImageDialogImage").cloneNode(true);
      const rect = document.getElementById("ImageDialogImage").getBoundingClientRect();
      window.canvas.hideCenterImageButton();
      window.Animator.findSimilar(image, {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height
      }, {});
      window.CanvasContainer.searchByImage(this.state.image.id, false, true);
      window.CanvasContainer.updateCentralImage(this.state.image.name, 0, this.state.image.id);
    }
  };

  handleClose() {
    this.setState({
      open: false,
    });
  }

  save() {
    const src = document.getElementById("ImageDialogImage").getAttribute('src');
    const name = document.getElementById("ImageDialogImage").getAttribute('name');
    this.forceDownload(src, name);
  }

  showResults() {
    this.setState({ isOpen: false });
    this.eventBus.publish('BackToResults');
  }

  forceDownload(url, fileName) {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  render() {
    if (this.props.chosenGraph === -1 || !this.state.image) return (<div></div>)
    const graphURL = createGraphURLPart()
    const src = this.state.image.id === -1 ? DELETED_IMAGE_URL : `${IMAGE_BASE_URL}/${graphURL}/images/${this.state.image.name}`

    return (

      <div style={styles.dialogContent}>
        {this.state.isOpen &&
          <div>
            {this.props.showSimilarImages.length > 0 &&
              <Button onClick={this.showResults} >
                <span style={{ marginBottom: "5px", color: mainColor }}>Back to results</span>
              </Button>
            }
            <Grid>
              <Grid item xs={12} className={this.props.classes.imgContainer}>
                <span className={this.props.classes.helper} />
                <img style={styles.image} id="ImageDialogImage" alt={src} src={src} name={this.state.image.name} />
              </Grid>
              <Grid item xs={12} className={this.props.classes.details}>

                <ButtonGroup variant="contained" className={this.props.classes.buttonGroup}>
                  <Button onClick={this.findSimilar} className={this.props.classes.button} color="secondary">
                    <Search style={{ marginRight: "4px" }} />
                    Search Similar
                  </Button>
                  <Button onClick={this.save} className={this.props.classes.button} color="secondary">
                    <GetApp />
                  </Button>

                </ButtonGroup>
                <ImageDetails image={this.state.image} />
              </Grid>
            </Grid>
          </div>}
      </div>


    );
  }
}

const styles = ({
  details: {
    textAlign: "center",
    position: 'relative',
    userSelect: "text"
  },
  imgContainer: {
    width: "400px",
    height: "400px",
    textAlign: 'center',
    background: 'white',
    marginBottom: 10
  },
  buttonGroup: {
    textAlign: 'center',

  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  dialog: {
    height: 640,
    zIndex: 2500
  },
  dialogContent: {
    paddingBottom: 10,
    paddingTop: 10,

  },
  helper: {
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'middle',
  }
});

function mapStateToProps(state) {
  return {
    showSimilarImages: state.search.similarImages,
    searchTerm: state.search.searchTerm,
    chosenGraph: state.settings.chosenGraph,
  }
}


export default connect(mapStateToProps)(withStyles(styles)(ImageDialog));



import React from 'react'
import { connect } from "react-redux";
import { getAvailableDatasets, initWorldMap, initViewPort, jumpToId, jumpToFile, move, similarImageUpload, searchText, filter, random, searchImageId } from '../../../API/jsonAPI';

import { action_change_graph, action_set_datasets, action_level_reset, action_similar_images, action_show_similar_images, action_level_transform, action_level, action_filter_results, action_filter_color_processing } from '../../../Modules/redux/actions';

import {MAX_LOCAL_MAP_SIZE} from '../../../config'
import Canvas from './Canvas'
import DataPreprocessor from './DataPreprocessor'
import { NotificationManager } from 'react-notifications';

const levelToTransformationPropertyMap = {
  0: {
    zoomFactor: 1.5,
    shapeValue: 0
  },
  1: {
    zoomFactor: 1,
    shapeValue: 20
  },
  2: {
    zoomFactor: 1,
    shapeValue: 40
  },
  3: {
    zoomFactor: 1,
    shapeValue: 60
  },
  4: {
    zoomFactor: 1,
    shapeValue: 80
  },
  5: {
    zoomFactor: 1,
    shapeValue: 90
  },
  6: {
    zoomFactor: 1,
    shapeValue: 100
  },
};

/**
* Prepares contents to be drawm
*/
export class CanvasContainer extends React.Component {

  constructor(props) {
    super(props);

    window.CanvasContainer = this; //Make this instance globally available

    this.state = {
      imageArray2D: [],
      newestMoveRequestTime: 0,
      requesting: false,
      canvasMoving: false,
      lastImage: null
    };
    this.message = "###";
    this.handleDrag = this.handleDrag.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.transformSphere = this.transformSphere.bind(this);
    window.lastSuccessfulFilter = props.filter
  }

  async initWorldMap() {

    await initWorldMap(window.sessionID, MAX_LOCAL_MAP_SIZE, MAX_LOCAL_MAP_SIZE);
    this.lastLevel = this.props.level;

  }

  async initViewPort(switchedGraph) {

    const data = await initViewPort(window.sessionID, this.props.gridSideLength, this.props.gridSideLength);

    if (data) {
      this.props.dispatch(action_level(data["level"]));


      const currentHash = window.location.hash.slice(1);
      const hashParts = currentHash.split("#")
      const image = hashParts[1]
      const ds = hashParts[0]


      //check if hash is in current link and abort if yes
      if ((Boolean(ds) || Boolean(image)) && !switchedGraph) {
        return
      }

      const imageArray2D = this.preprocessData(data);

      const midX = parseInt(data.map.columns / 2)
      const midY = parseInt(data.map.rows / 2)

      // debugger;
      const filename = imageArray2D[midX][midY].name
      const dataset = this.props.datasets[this.props.chosenGraph].name


      // console.log(dataset)
      const dsAndFilename = dataset + "#" + filename

      if (window.location.hash.slice(1) !== dsAndFilename) {

        window.history.pushState({ hash: dsAndFilename }, "", "#" + dsAndFilename);
      }
    }
  }

  /**
   * Jump to an random image
   *
   * @return {Promise<void>}
   */
  async randomizeViewport() {
    // if (window.canvas && window.canvas.isBusy) return;

    const data = await random(window.sessionID, this.props.level);
    if (data) {
      const centralImageIndex = Math.floor(this.props.gridSideLength / 2);
      this.moveToImage(data, this.props.level, centralImageIndex, centralImageIndex);

    }
  }

  async move(x, y, directionX, directionY, skimming) {

    //console.log("move " + x + " " + y)
    const currentMoveRequestTime = new Date().getTime();
    this.setState({
      latestMoveRequest: currentMoveRequestTime,
      canvasMoving: true
    });
    // const oldCanvasX = window.canvas.xm;
    // const oldCanvasY = window.canvas.ym;
    const data = await move(window.sessionID, x, y, directionX, directionY);

    // let transformX = Math.trunc(window.canvas.xm) - Math.trunc(oldCanvasX);
    // let transformY = Math.trunc(window.canvas.ym) - Math.trunc(oldCanvasY);
    //debugger;#

    const isLatestResponse = currentMoveRequestTime === this.state.latestMoveRequest;
    // if(skimming){
    //   transformX = 0;
    //   transformY = 0;
    // }

    if (isLatestResponse) {
      this.setState({
        canvasMoving: false
      })
    }
    // && (currentMoveRequestTime === this.state.latestMoveRequest)
    if (data && isLatestResponse)
      this.preprocessData(data);
  }


  /**
   * Stops all image loading processes of very element in the imageArray2D.
   */
  cleanImageQueue() {
    this.state.imageArray2D.map(dim1 => {
      return dim1.map((item) => {
        if (item && !item.loaded && item !== "preview") {
          item.src = "";
        }
        return item
      })
    });
  }

  preprocessData(data) {
    let imageArray2D
    if (data) {
      // this.cleanImageQueue();
      imageArray2D = new DataPreprocessor().startProcessingPipeline(data.map, this.props.gridSideLength);
      // debugger;
      this.setState({ "imageArray2D": imageArray2D });
      this.canvasHasBeenUpdated();
    }
    return imageArray2D
  }


  newImageUploaded(oldProps, newProps) {
    return newProps.search.uploaded_img !== oldProps.search.uploaded_img && newProps.search.uploaded_img !== '';
  }

  checkIfPropsChanged(oldSearchTerm, newSearchTerm) {
    return JSON.stringify(oldSearchTerm) !== JSON.stringify(newSearchTerm);
  }


  findSimilarImages(uploadedImg) {
    if (uploadedImg) {
      this.props.dispatch(action_filter_color_processing(true));
      this.searchSimiliarImage(uploadedImg);
    }
  }

  /**
   *
   * @param props
   * @return {Promise<void>}
   * TODO needs full rewrite once filters are live in backend
   */
  async filterChange(props) {

    this.props.dispatch(action_filter_color_processing(true));
    filter(window.sessionID, false).then((filterLevelResults) => {
      this.handleFilterLevelResults(filterLevelResults);
      this.handleLevel();
      // console.log("filter change", props.search.searchTerm)
      this.changeFilters(props.search.searchTerm);
    })
  }

  handleFilterLevelResults(filterLevelResults) {
    // console.log(filterLevelResults)

    const newFilterLevelResults = []
    for (let i = 0; i < filterLevelResults.length; i++) {
      const element = filterLevelResults[i];
      if (element > 100) newFilterLevelResults.push(element)
    }

    // console.log(newFilterLevelResults)

    if (filterLevelResults) this.props.dispatch(action_filter_results(newFilterLevelResults));
  }

  async handleLevel() {

    if (this.props.filterLevelResults[this.props.level] > window.levelResultsThreshold) return;

    let queryLevel = this.props.level;
    while (queryLevel >= 0) {
      if (this.props.filterLevelResults[queryLevel] > window.levelResultsThreshold || queryLevel === 0) {
        const image = window.canvas.getClosestImageToCenter();
        this.handleZoom(image, queryLevel, true);
        break;
      }
      queryLevel--;
    }
  }


  async changeFilters(searchTerm) {
    
    // text and color search
    if (!this.state.lastImage && (searchTerm !== "" || this.props.filter.color.length !== 0)) {
      this.props.dispatch(action_show_similar_images(true));
      this.searchBytext(searchTerm, false);
      
    }
    // upload search
    if (this.props.search.uploaded_img !== "") {
      this.findSimilarImages(this.props.search.uploaded_img)

      // only filters are active
    } else {
      
      // jumpToId to the center image, after applying the filter
      const image = window.canvas.getClosestImageToCenter();
      if (image)
        this.moveToImage(image.id, this.props.level, parseInt(image.getAttribute("posx")), parseInt(image.getAttribute("posy")));
      else {
        const centralImageIndex = Math.floor(this.props.gridSideLength / 2);
        this.moveToImage(this.state.lastImage, this.props.level, centralImageIndex, centralImageIndex);
      }
      this.props.dispatch(action_filter_color_processing(false));
    }
  }

  /**
   * Pure text search
   *
   * @param searchTerm
   */
  textSearch(searchTerm) {
    this.props.dispatch(action_filter_color_processing(true));
    this.props.dispatch(action_show_similar_images(true));
    this.searchBytext(searchTerm);
  }

  async searchSimiliarImage(imgFile) {

    this.props.dispatch(action_filter_color_processing(true));
    await similarImageUpload(window.sessionID, imgFile, 9).then(async (similarImages) => {
      if (similarImages && similarImages.length > 0) this.handleSearch(similarImages);
      else {
        NotificationManager.warning('No search results!', '', 3000, () => { }, true);
      }
      this.props.dispatch(action_filter_color_processing(false));
    })
  }

  async searchBytext(text, notify = true) {

    if (!text || text === "") return
    this.props.dispatch(action_filter_color_processing(true));
    await searchText(window.sessionID, text, 9).then(async (similarImages) => {

      if (similarImages && similarImages.length > 0) this.handleSearch(similarImages);
      else if (notify) {
        NotificationManager.warning('No search results!', '', 3000, () => { }, true);
      }
    });
    this.props.dispatch(action_filter_color_processing(false));

  }

  async searchByImage(imageId, notify = true, skipAnimation = true) {
    this.props.dispatch(action_show_similar_images(true));
    // this action will close all filter widgets in the sidebar, usefull for later
    // this.props.dispatch(action_filter_color_processing(true));
    await searchImageId(window.sessionID, imageId, 9).then(async (similarImages) => {

      if (similarImages && similarImages.length > 0) {
        similarImages[0].skipAnimation = skipAnimation;
        this.handleSearch(similarImages);
      } else if (notify) {
        NotificationManager.warning('No search results!', '', 3000, () => { }, true);
      }
    });
    // this.props.dispatch(action_filter_color_processing(false));

  }

  async handleSearch(similarImages) {
    // window.Animator.showOverlayCanvas();
    const mostSimilarImage = similarImages[0];
    this.showSimilarImages(similarImages);
    if (mostSimilarImage)
      this.props.dispatch(action_level_reset());
  }

  showSimilarImages(similarImages) {
    window.canvas.hideCenterImageButton();
    this.props.dispatch(action_similar_images(similarImages));
  }

  /**
   * Set a new central image given by the filename or id
   *
   * @param {string} filename used to update the URL hash
   * @param {number} level
   * @param {number} [id]
   * @return {Promise<void>}
   */
  async updateCentralImage(filename, level = 0, id) {

    // update the hash of the URL to create a unique link to this image
    const dataset = this.props.datasets[this.props.chosenGraph].name
    const dsAndFilename = dataset + "#" + filename
    if (window.location.hash.slice(1) !== dsAndFilename) {

      window.history.pushState({ hash: dsAndFilename }, "", "#" + dsAndFilename);
    }


    if (level === 0)
      this.props.dispatch(action_level_reset());

    // move the viewport
    const { posX, posY } = window.canvas.getCentralImagePosition();
    if (id) {
      return this.moveToImage(id, level, posX, posY);
    } else {
      return new Promise((resolve, reject) => {
        // console.log("jump to", filename)
        jumpToFile(window.sessionID, filename, posX, posY, level)
          .then((data) => {
            if (String(data).includes("Error")) {
              reject()
            } else {
              if (data)
                this.preprocessData(data);
              resolve()
            }
          }
          )
      });
    }
  }


  /**
   * Place the image given by the id at the given position on the canvas
   * and place similar images of the given level around it
   *
   * @param {number} id
   * @param {number} level
   * @param {number} posX
   * @param {number} posY
   * @return {Promise<void>}
   */
  async moveToImage(id, level = 0, posX = 9, posY = 9) {
    this.setState({ lastImage: id })

    if (level === 0)
      this.props.dispatch(action_level_reset());

    return new Promise((resolve, reject) => {
      jumpToId(window.sessionID, id, posX, posY, level)
        .then((data) => {
          if (String(data).includes("Error")) {
            reject()
          } else {
            if (data)
              this.preprocessData(data);
            resolve()
          }
        }
        )
    });
  }

  /**
   * Event handler
   *
   * @param xDirection
   * @param yDirection
   * @param skimming
   * @return {Promise<void>}
   */
  async handleDrag(xDirection, yDirection, skimming = false) {
    //console.log("handleDrag", xDirection, yDirection) 
    const x = -xDirection;
    const y = -yDirection;

    if (Math.round(x) === 0 && Math.round(y) === 0)
      return;

    const dist = Math.sqrt(x * x + y * y);
    if (this.state.imageArray2D.length > 0)
      await this.move(Math.round(x), Math.round(y), x / dist, y / dist, skimming);  // TODO await weg?
  }


  async handleZoom(targetImage, level, skipAnimation) {
    if (window.canvas.isBusy || level >= this.props.numberOfLayers || level < 0) return;
    window.canvas.hideCenterImageButton();
    this.props.dispatch(action_level(level));
    window.canvas.isBusy = true;
    if (!skipAnimation) this.showZoomAnimation(targetImage, level);
    if (targetImage)
      return await this.moveToImage(targetImage.id, level, targetImage.getAttribute("posx"), targetImage.getAttribute("posy"));
    else
      return null
  }


  showZoomAnimation(targetImage, level) {
    const top = parseInt(targetImage.getAttribute('coordy'));
    const left = parseInt(targetImage.getAttribute('coordx'));
    const width = parseInt(targetImage.getAttribute('xsize'));
    const height = parseInt(targetImage.getAttribute('ysize'));

    const zoomIn = this.lastLevel > level;
    window.Animator.zoom(targetImage.cloneNode(true), { top, left, width, height }, zoomIn);
    this.lastLevel = level;
  }

  canvasHasBeenUpdated() {

    this.manageSphereTransformation();
    if (window.Animator) window.Animator.canvasHasBeenUpdated();
    // window.canvas.drawImagesOnCanvas();

    setTimeout(() => {
      //Draw images AGAIN after canvas has been updated. --> Images will be drawn from outside to inside --> central images will have higher zIndex
      window.canvas.drawImagesOnCanvas();
    }, 350);
  }

  manageSphereTransformation() {
    if (this.props.autoTransform) {
      this.transformSphere(this.props.level);
    }
  }

  transformSphere(level) {
    this.props.dispatch(action_level_transform({
      zoomFactor: levelToTransformationPropertyMap[level].zoomFactor,
      shapeValue: levelToTransformationPropertyMap[level].shapeValue
    }));
  }



  /**
   * Lifecycle methods
   * @override
   */
  async componentDidMount() {
    const datasets = await getAvailableDatasets()
    await this.props.dispatch(action_set_datasets(datasets))

    window.addEventListener('popstate', async (e) => {
      if (!Boolean(e.state)) return

      const currentHash = e.state.hash
      const hashParts = currentHash.split("#")
      const ds = hashParts[0].replace("%20", " ")
      const image = hashParts[1]

      if (Boolean(ds)) {

        const datasetNames = this.props.datasets.map((e) => e.name)
        const value = datasetNames.indexOf(ds)

        if (value != this.props.chosenGraph) {
          await this.props.dispatch(action_change_graph(Object.assign(value, {})))
          await this.initWorldMap()
          await this.initViewPort(false)
        }
        if (Boolean(image)) {
          await this.initViewPort(false)
          await this.updateCentralImage(image);
        }
        else {
          await this.initViewPort(false)
          await this.randomizeViewport()
        }
      }
    });


    const currentHash = window.location.hash.slice(1);
    const hashParts = currentHash.split("#")
    const ds = hashParts[0].replace("%20", " ")
    const image = hashParts[1]

    if (Boolean(ds)) {

      const datasetNames = this.props.datasets.map((e) => e.name)
      const value = datasetNames.indexOf(ds)

      await this.props.dispatch(action_change_graph(Object.assign(value, {})))
      await this.initWorldMap()


      if (Boolean(image)) {
        await this.initViewPort(false)
        await this.updateCentralImage(image);
      }
      else {
        await this.initViewPort(false)
        await this.randomizeViewport()
      }

    }
    else {

      let firstVisibleDsIndex = -1

      for (let i = 0; i < this.props.datasets.length; i++) {
        const ds = this.props.datasets[i]

        if (ds.visible) {
          firstVisibleDsIndex = i
          break
        }
      }
      
      await this.props.dispatch(action_change_graph(Object.assign(firstVisibleDsIndex, {})))
      await this.initWorldMap()
      await this.initViewPort()
      
      // await this.props.dispatch(action_change_graph(Object.assign(firstVisibleDsIndex, {})))

    }
  }


  /**
   * @override
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  async componentDidUpdate(prevProps, prevState, snapshot) {


    if (this.checkIfPropsChanged(prevProps.filter.filterList, this.props.filter.filterList))
      this.filterChange(this.props);

    if (this.checkIfPropsChanged(prevProps.search.searchTerm, this.props.search.searchTerm) && this.props.search.searchTerm && this.props.search.searchTerm !== "") {
      this.textSearch(this.props.search.searchTerm);
    }

    if (this.checkIfPropsChanged(prevProps.filter.color, this.props.filter.color) && this.props.filter.color.length > 0)
      //TODO add color search
      // this.co(this.props.searchTerm);
      // console.log("color search not added yet")
      this.filterChange(this.props);

    if (this.props.search.uploaded_img !== "" && this.props.search.uploaded_img !== prevProps.search.uploaded_img)
      this.findSimilarImages(this.props.search.uploaded_img)

    if (Number(prevProps.chosenGraph) !== Number(this.props.chosenGraph)) {
      if (Number(prevProps.chosenGraph) !== -1) {
        this.cleanImageQueue()
        await this.initWorldMap()
        await this.initViewPort(true)
      }
    }
    if ((prevProps.gridSideLength !== this.props.gridSideLength) || (prevProps.selectedImageType !== this.props.selectedImageType)) {
      const data = await initViewPort(window.sessionID, this.props.gridSideLength, this.props.gridSideLength);
      if (data) {
        // const centralImageIndex = Math.floor(this.props.gridSideLength / 2);
        // const centraImage = data.map.tiles.filter(element => element.x === centralImageIndex && element.y === centralImageIndex)[0]
        //this.moveToImage(centraImage.content.id, this.props.level, centralImageIndex, centralImageIndex);
        this.preprocessData(data)
      }
    }



  }


  render() {


    return (
      <div id="CanvasContainer" style={{ width: '100%', height: '100%', background: 'rgb(' + this.props.backgroundColor + ',' + this.props.backgroundColor + ',' + this.props.backgroundColor + ')' }}>
        <Canvas num={this.props.gridSideLength}
          imageArray2D={this.state.imageArray2D}
          handleDrag={this.handleDrag}
          handleZoom={this.handleZoom}
          theme={this.props.theme}
          isMoving={this.state.canvasMoving}
        />

      </div>
    );
  }
}

function mapStateToProps(state) {

  const layers = state.settings.availableDatasets.map(e => e.layers)

  let layer = 0
  if (layers)
    layer = Number(layers[state.settings.chosenGraph])

  return {
    gridSideLength: state.settings.gridSideLength,
    autoTransform: state.settings.autoTransform,
    level: state.level.level,
    shapeValue: state.settings.shapeValue,
    zoomFactor: state.settings.zoomFactor,
    filter: state.filter,
    search: state.search,
    showSimilarImages: state.search.similarImages,
    filterLevelResults: state.filter.filterLevelResults,
    backgroundColor: state.settings.backgroundColor,
    chosenGraph: state.settings.chosenGraph,
    datasets: state.settings.availableDatasets,
    numberOfLayers: layer,
    selectedImageType: state.settings.selectedImageType
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(CanvasContainer);
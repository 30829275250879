import { createTheme  } from '@material-ui/core/styles';

//const font = 'Gugi';

const theme = createTheme ({
    palette: {
        primary: {
            light: '#F0F0F0',
            main: '#3C486B',
            dark: '#53585FFF',
            red: '#F45050'
        },
        secondary: {
            light: '#F0F0F0',
            main: '#3C486B',
            dark: '#53585FFF',
            red: '#F45050'
        },
        action: {
            active: '#3C486B',
        },
        background: {
            paper: '#fff',
            main: '#DCDCDC',
        },
        text: {
            primary: '#000'
        }
        // error: will use the default color
    },
    typography: {
        //fontFamily: font,
        fontSize: 12,
    },
    overrides: {
        MuiFormLabel: {
            root: {
                // margin: "0 0 10px 10px",
            }
        },
        MuiInputBase: {
            input: {
                padding: "12px",
                width: "100%"
            },
            inputMarginDense: {
                padding: "5px 5px 12px 5px",
            }
        },
        MuiInputAdornment: {
          positionEnd: {
            marginLeft: 0
          }
        },
        MuiToggleButton: {
            root: {
                border: "0px",
                '&$selected': { // <-- mixing the two classes
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiFab:{
            root:{
                "&:disabled": {
                    background: "#888"
                },
            },
            secondary:{
                "&:disabled": {
                    "&:hover": {
                        backgroundColor: "#888",
                        cursor: "default"
                    },
                },
                backgroundColor: "rgb(17, 82, 137)",
            }
        },
        MuiCollapse: {
            // entered: {
            //     height: "220px !important",
            // }
        },
        MuiDivider:{
            middle:{
                margin: "20px 0",
            }
        },
        MuiDialog:{
            paper:{
                paddingTop: "15px"
            }
        }
    }
});

export default theme;

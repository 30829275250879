export const actionTypes = {
  SUBMIT_SEARCH_TERM: "SUBMIT_SEARCH_TERM",
  SIMILAR_IMAGES: "SIMILAR_IMAGES",
  SHOW_SIMILAR_IMAGES: "SHOW_SIMILAR_IMAGES",
  SHAPE_VALUE: "SHAPE_VALUE",
  LEVEL_TRANSFORM: "LEVEL_TRANSFORM",
  IMAGE_SPACE: "IMAGE_SPACE",
  GRID_SIDE_LENGTH: "GRID_SIDE_LENGTH",
  LEVEL_ZOOM_OUT: "LEVEL_ZOOM_OUT",
  LEVEL_ZOOM_IN: "LEVEL_ZOOM_IN",
  LEVEL_RESET: "LEVEL_RESET",
  FILTER_COLOR: "FILTER_COLOR",
  UPLOADED_IMAGE: "UPLOADED_IMAGE",
  LEVEL: "LEVEL",
  SEARCH_TAGS: "SEARCH_TAGS",
  FILTER_RESULTS: "FILTER_RESULTS",
  BACKGROUND_COLOR: "BACKGROUND_COLOR",
  FILTER_LICENSE: "FILTER_LICENSE",
  FILTER_COLOR_PROCESSING: "FILTER_COLOR_PROCESSING",
  CHANGE_FILTERS: "CHANGE_FILTERS",
  SESSION_EXPIRED: "SESSION_EXPIRED",
  CHANGE_GRAPH: "CHANGE_GRAPH",
  SET_DATASETS: "SET_DATASETS",
  SET_IMAGE_TYPE: "SET_IMAGE_TYPE"
};

/**
 * 
 * Filter object should look like something like this
 * name:
 * values:{
 *  1:0, n2: 2
 * }
 * displayValue: str
 * 
 */
export const action_set_image_Type = (imageType) => {
  return {
    type: actionTypes.SET_IMAGE_TYPE,
    payload: {imageType},
  }
};

export const action_change_filters = (filters) => {
  return {
    type: actionTypes.CHANGE_FILTERS,
    payload: {filters},
  }
};

export const action_set_datasets = (datasets) => {
  return {
    type: actionTypes.SET_DATASETS,
    payload: {datasets},
  }
};

export const action_change_graph = (graph) => {
  return {
    type: actionTypes.CHANGE_GRAPH,
    payload: {graph},
  }
};

export const action_submit_search_term = (searchTerm) => {
  return {
    type: actionTypes.SUBMIT_SEARCH_TERM,
    payload: {searchTerm},
  }
};

export const action_similar_images = (similarImages) => {
  return {
    type: actionTypes.SIMILAR_IMAGES,
    payload: {similarImages},
  }
};

export const action_show_similar_images = (showSimilarImages) => {
  return {
    type: actionTypes.SHOW_SIMILAR_IMAGES,
    payload: {showSimilarImages},
  }
};

export const action_shape_value = (shapeValue) => {
  return {
    type: actionTypes.SHAPE_VALUE,
    payload: {shapeValue},
  }
};

export const action_gridside_length = (gridSideLength) => {
  return {
    type: actionTypes.GRID_SIDE_LENGTH,
    payload: {gridSideLength},
  }
};

export const action_level_transform = (config) => {
  return {
    type: actionTypes.LEVEL_TRANSFORM,
    payload: {config},
  }
};

export const action_level = (level) => {
  return {
    type: actionTypes.LEVEL,
    payload: {level},
  }
};

export const action_level_reset = () => {
  return {
    type: actionTypes.LEVEL_RESET
  }
};

/**
 * Is the color search being processed?
 *
 * @param colorIsProcessing
 * @return {{payload: {colorIsProcessing: *}, type: *}}
 */
export const action_filter_color_processing = (colorIsProcessing) => {
  return {
    type: actionTypes.FILTER_COLOR_PROCESSING,
    payload: {colorIsProcessing}
  }
};

export const action_filter_color = (colorFilter) => {
  return {
    type: actionTypes.FILTER_COLOR,
    payload: {colorFilter}
  }
};

export const action_filter_license = (licenseFilter) => {
  return {
    type: actionTypes.FILTER_LICENSE,
    payload: {licenseFilter}
  }
};

export const action_upload_image = (uploaded_img) => {
  return {
    type: actionTypes.UPLOADED_IMAGE,
    payload: {uploaded_img}
  }
};

export const action_search_tags = (suggestedTags) => {
  return {
    type: actionTypes.SEARCH_TAGS,
    payload: {suggestedTags}
  }
};

export const action_filter_results = (filterResults) => {
  return {
    type: actionTypes.FILTER_RESULTS,
    payload: {filterResults}
  }
};

export const action_background_color = (backgroundColor) => {
  return {
    type: actionTypes.BACKGROUND_COLOR,
    payload: {backgroundColor}
  }
};

export const action_session_expired = (isExpired) => {
  return {
    type: actionTypes.SESSION_EXPIRED,
    payload: {isExpired}
  }
};

import React from 'react';
import { connect } from "react-redux";
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import { createKey, addFilter, removeFilter } from "./utils";

import {
    action_change_filters,
} from '../../../Modules/redux/actions';

class EnumFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValues: props.displayValues.length > 1 ? props.displayValues.slice() : [],
            filterKey: createKey(this.props.filterName)
        };
        this.handleAttributeChange = this.handleAttributeChange.bind(this);
    }

    findIndices(arr1, arr2) {
        let indices = [];
        for (let i = 0; i < arr1.length; i++) {
            let index = arr2.indexOf(arr1[i]);
            if (index !== -1) {
                indices.push(index);
            }
        }
        return indices;
    }

    collectValuesByIndices(indices, values) {
        let collectedValues = [];
        for (let i = 0; i < indices.length; i++) {
            let index = indices[i];
            if (index >= 0 && index < values.length) {
                collectedValues.push(values[index]);
            }
        }
        return collectedValues;
    }

    handleAttributeChange(event) {
        const { value, checked } = event.target;
        const { selectedValues } = this.state;

        let updatedAttributes = selectedValues.slice();

        if (checked) {
            updatedAttributes.push(value);
        } else {
            updatedAttributes = updatedAttributes.filter((a) => a !== value);
        }

        // console.log(updatedAttributes)

        this.setState({
            selectedValues: updatedAttributes,
        });

        let newList;
        //EnumFilter
        if (this.props.displayValues.length > 1) {

            const attributeIndices = this.findIndices(updatedAttributes, this.props.displayValues)

            const valObj = this.collectValuesByIndices(attributeIndices, this.props.attributes)

            const filterObj = {
                key: this.state.filterKey,
                name: this.props.filterName,
                values: valObj,
                displayValue: ""
            }
            const newList1 = removeFilter(filterObj, this.props.filterList)
            newList = addFilter(filterObj, newList1)
            // Boolean Filter    
        } else {
            const filterObj = {
                key: this.state.filterKey,
                name: this.props.filterName,
                values: checked,
                displayValue: ""
            }

            if (checked) {
                newList = addFilter(filterObj, this.props.filterList)
            } else {
                newList = removeFilter(filterObj, this.props.filterList)
            }
        }
        this.props.dispatch(action_change_filters(newList));

    }

    render() {
        const { displayValues } = this.props;
        const { selectedValues } = this.state;

        return (
            <div className={this.props.classes.rootContainer}>
                <p className={this.props.classes.filterText}>{this.props.prefix}</p>
                <hr />
                <Grid container={true} spacing={displayValues.length > 1 ? 3 : 0}>
                    {displayValues.map((attribute) => (
                        <Grid item xs={12} sm={6} md={6} key={attribute}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedValues.includes(attribute)}
                                        onChange={this.handleAttributeChange}
                                        value={attribute}
                                    />
                                }
                                label={attribute}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>

        );
    }
}

const styles = ({

    rootContainer: {
        margin: '5px',
        display: "flex",
        flexDirection: 'column',
    },
    filterText: {
        margin: "auto",
        padding: "2px"
    },
});

function mapStateToProps(state) {
    return {
        filterList: state.filter.filterList,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(EnumFilter));

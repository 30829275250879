import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';


class ImageDetails extends React.Component {

  constructor(props){
    super(props);
    this.state = {imageInfo: false};
    
  }


  formatTitle(image){
    let title = "This image was deleted from the database"
  
    if (image.id > -1){
      title = image.name.replace(/_/g, ' ').split("/").at(-1)
    }
    return title
  }


  async imageInfoToState(){
    
    this.setState({
      title: this.formatTitle(this.props.image),
      imageInfo: true
    });
    
  }

  /**
   * @override
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.image !== prevProps.image) this.imageInfoToState();
  }

  componentDidMount(){
    if(this.props.image && this.props.image.name){
      this.imageInfoToState()
    }
  }

  render() {

    if(this.state.imageInfo){
      return (
          <div className={this.props.classes.detailsWrapper}>
            <div className={this.props.classes.details}>
              <Box m={5}>
                <p>{this.state.title}</p>
              </Box>
            </div>
          </div>
      );
    } else {
      return (
        <div className={this.props.classes.detailsWrapper}>
          <div style={{ height: "50%", position: "absolute", top: "50%", left: "50%"}}>
              <CircularProgress/>
          </div>
        </div>
      )
    }


  }
}

const styles = ({
    detailsWrapper: {
        minHeight: 200,
        marginBottom: 8,
        display : 'flex',
        alignItems : 'center',
      },
      details: {
        width: '100%'
      },
      description: {
        textAlign: 'justify',
      }
  });

export default withStyles(styles)(ImageDetails);

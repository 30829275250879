import React from 'react';
import { connect } from "react-redux";
import { IconButton } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import CheckIcon from "@material-ui/icons/Check";
import { createKey, addFilter } from "./utils";
import { DatePicker } from '@mui/x-date-pickers';

import {
    action_change_filters,
} from '../../../Modules/redux/actions';

class DateRangeFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            start: null,
            end: null,

        };
        this.handleConfirm = this.handleConfirm.bind(this);
    }



    handleConfirm() {

        const valObj = {
            min:this.state.start ? this.state.start.$d.toISOString() : new Date(0, 0, 1).toISOString(),
            max: this.state.end ? this.state.end.$d.toISOString() : new Date().toISOString(),
        }

        const key = createKey(this.props.filterName)

        const options = { day: '2-digit', month: '2-digit', year: 'numeric' }; // Set the formatting options

        const startString = this.state.start ? this.state.start.$d.toLocaleDateString('de', options) : "";
        const endString = this.state.end ? this.state.end.$d.toLocaleDateString('de', options) : "";

        let displayValue = `${this.props.prefix}: ${startString} - ${endString} ${this.props.suffix}`


        const filterObj = {
            key: key,
            name: this.props.filterName,
            values: valObj,
            displayValue: displayValue
        }

        const newList = addFilter(filterObj, this.props.filterList)

        this.props.dispatch(action_change_filters(newList));

        this.setState({
            start: null,
            end: null,
        });
    }

    render() {

        return (
            <div className={this.props.classes.rootContainer}>
                <p className={this.props.classes.filterText}>{this.props.prefix}</p>
                <hr />
                <div className={this.props.classes.valueContainer}>

                    <DatePicker
                        className={this.props.classes.dateField}
                        edge="end"
                        value={this.state.start}
                        label="Start Date"
                        color="secondary"
                        onChange={(event)=> this.setState({start: event})} />
                    
                    <DatePicker
                        edge="end"
                        
                        className={this.props.classes.dateField}
                        label="End Date"
                        color="secondary"
                        onChange={(event)=> this.setState({end: event})} />



                    <IconButton
                        edge="end"
                        aria-label="Confirm"
                        className={this.props.classes.confirmButton}
                        color="secondary"
                        onClick={this.handleConfirm}>
                        <CheckIcon />
                    </IconButton>

                </div>
            </div>
        );
    }
}

const styles = ({

    rootContainer: {
        margin: '5px',
        display: "flex",
        marginBottom: "20px",
        flexDirection: 'column',
    },
    filterText: {
        margin: "auto",
        padding: "2px",
    },
    valueContainer: {
        marginTop: "5px",
        marginRight: "-15px",
        display: "flex"
    },
    confirmButton: {
        marginLeft: "auto",
        marginRight: "10px"
    },
    dateField: {
        width: '170px', // Set the width of the component
        height: '50px',
        margin: 'auto',
        '& .MuiInputBase-root': {
          fontSize: '0.875rem', // Set the font size of the input field
          lineHeight: '1.25rem', // Set the line height of the input field,
          marginRight: "10px"
        }
    }
});

function mapStateToProps(state) {
    return {
        filterList: state.filter.filterList,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(DateRangeFilter));

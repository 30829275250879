import React from 'react'
import { connect } from "react-redux";
import { action_filter_color, action_filter_license, action_upload_image, action_change_filters } from '../../../Modules/redux/actions'
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Close from "@material-ui/icons/Close";
import withStyles from "@material-ui/core/styles/withStyles";
import Chip from '@material-ui/core/Chip';
import { ccIcon, saIcon, byIcon, otIcon, pdIcon } from "../../../lib/LicenseIcons"
import { removeFilter } from "./utils";

import styleTheme from "../../../styleTheme";

const mainColor = styleTheme.palette.secondary.dark
const chipColor = styleTheme.palette.secondary.red

const licenseDict = {
  "cc": ccIcon,
  "sa": saIcon,
  "by": byIcon,
  "pd": pdIcon,
  "ot": otIcon
};

class FilterDisplay extends React.Component {

  constructor(props) {
    super(props);

    this.handleDeleteColor = this.handleDeleteColor.bind(this);
    this.renderColors = this.renderColors.bind(this);

    this.clearFilter = this.clearFilter.bind(this);

    this.handleDeleteLicense = this.handleDeleteLicense.bind(this);
    this.handeDeleteImage = this.handeDeleteImage.bind(this);
    this.handleDeleteFilter = this.handleDeleteFilter.bind(this);
    this.renderUploadedImage = this.renderUploadedImage.bind(this);

    window.FilterDisplay = this;
  }

  handleDeleteColor = color => () => {
    const index = this.props.filter.color.indexOf(color);
    if (index > -1) {
      const colors = this.props.filter.color.slice();
      colors.splice(index, 1);
      this.props.dispatch(action_filter_color(Object.assign(colors, {})));
    }
  };

  handleDeleteLicense = license => () => {
    const index = this.props.filter.licenses.indexOf(license);
    if (index > -1) {
      const licenses = this.props.filter.licenses.slice();
      licenses.splice(index, 1);
      this.props.dispatch(action_filter_license(Object.assign(licenses, {})));
    }
  };

  handleDeleteFilter = filterObj => () => {
    const newList = removeFilter(filterObj, this.props.filterList)
    this.props.dispatch(action_change_filters(newList));

  };

  handeDeleteImage() {
    this.props.dispatch(action_upload_image(""))
  }

  renderColors() {
    let colors = [];
    for (let i = 0; i < this.props.filter.color.length; i++) {
      colors.push(<div key={this.props.filter.color[i]}
        className={this.props.classes.colorPoint}
        style={{
          backgroundColor: this.props.filter.color[i], height: '24px',
          borderRadius: '50%', width: '24px', display: 'inline-block',
          position: 'relative', margin: '5px',
          textAlign: 'center', color: 'white', border: '1px solid black'
        }}
        onClick={this.handleDeleteColor(this.props.filter.color[i])}><div><Close /></div>

      </div>)
    }
    return colors;
  }

  renderLicenses() {
    let licenses = [];
    for (let i = 0; i < this.props.filter.licenses.length; i++) {

      licenses.push(<div key={this.props.filter.licenses[i]} className={this.props.classes.licensePoint}
        style={{
          height: '24px',
          width: '24px', display: 'inline-block',
          position: 'relative', margin: '4px',
          textAlign: 'center'
        }}
        onClick={this.handleDeleteLicense(this.props.filter.licenses[i])}>
        <svg aria-hidden="true" focusable="false"
          role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox={licenseDict[this.props.filter.licenses[i]].viewBox}
          style={{ height: '24px', color: "red", width: '24px' }}
        >

          <path fill="currentColor" d={licenseDict[this.props.filter.licenses[i]].path} />
        </svg>
        <div><Close /></div>
      </div>)
    }
    return licenses;
  }

  renderUploadedImage() {

    // && this.props.filter.color.length + this.props.currentSearchTerms.length === 0
    if (this.props.search.uploaded_img !== "" && this.props.resizedImg !== "") {
      //const resizedImg = await resizeImage(this.props.search.uploaded_img, 24, true)
      return (
        <div
          className={this.props.classes.uploadImage}
          style={{
            backgroundImage: `url(${this.props.resizedImg})`, height: '40x',
            borderRadius: '10%', width: '40px', display: 'inline-block',
            position: 'relative', margin: '5px',
            textAlign: 'center', color: 'white', border: '1px solid black',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
          onClick={this.handeDeleteImage}><div><Close style={{ marginTop: "8px" }} /></div>
        </div>
      )

    }
  }

  clearFilter() {
    this.props.resetSearch();
  }

  showCircularProgress() {
    if (this.props.colorIsProcessing) {
      return (
        <CircularProgress size={20} style={{ marginRight: "10px", marginTop: "10px", marginBottom: "14px" }} />
      )
    } else {
      return (
        <IconButton onClick={this.props.resetSearch}>
          <Close />
        </IconButton>)
    }
  }

  render() {
    const colors = this.renderColors();
    const licenses = this.renderLicenses();
    const uploadedImage = this.renderUploadedImage();
    // || this.props.currentSearchTerms.length + colors.length + licenses.length > 0
    // console.log(this.props.filterList.length)


    const chipStyleSelected = {
      margin: '3px',
      height: '26px',
      backgroundColor: chipColor,
      color: 'white'

    }
    let filterDisplays = [];

    this.props.filterList.forEach((item) => {
      if (item.displayValue !== "")
        filterDisplays.push(
          <Chip
            style={chipStyleSelected}
            key={item.key}
            label={item.displayValue}
            onDelete={this.handleDeleteFilter(item)} />)

    });
    if (filterDisplays.length > 0 || this.props.colorIsProcessing || colors.length > 0 || this.props.resizedImg !== "") {
      return (
        <div className={this.props.classes.activeFilterContainer} >
          <div className={this.props.classes.actionButtonWrapper}>
            {/* <IconButton onClick={this.clearFilter}>
                      <Clear/>
                  </IconButton> */}
            {this.showCircularProgress()}
          </div>
          <div className={this.props.classes.tagsWrapper}>
            {filterDisplays}
            {uploadedImage}
            {this.props.currentSearchTerms}
            {licenses}
            {colors}
          </div>

        </div >
      );
    } else {
      return (<div style={{ marginRight: "10px", marginTop: "5px", marginBottom: "5px" }}/>)
    }
  }
}

function mapStateToProps(globalState, ownProps) {
  return {
    filter: globalState.filter,
    filterList: globalState.filter.filterList,
    search: Object.assign({}, globalState.search),
    colorIsProcessing: globalState.filter.colorIsProcessing,
    licenseIsProcessing: globalState.filter.licenseIsProcessing,
    currentSearchTerms: ownProps.currentSearchTerms,
    resetSearch: ownProps.resetSearch
  }
}

const styles = {
  colorPoint: {
    '& div:first-child': {
      display: "none"
    },
    '&:hover': {
      border: "3px solid black",
      '& div:first-child': {
        display: "table",
        borderRadius: "10px",
        lineHeight: "17px",
        color: "black",
        backgroundColor: "rgba(255,255,255,0.5)",
      }
    }
  },
  uploadImage: {
    '& div:first-child': {
      display: "none"
    },
    '&:hover': {
      border: "3px solid black",
      '& div:first-child': {
        display: "table",
        height: "38px",
        width: "38px",
        borderRadius: "50%",
        color: "black",
        backgroundColor: "rgba(255,255,255,0.5)",
      }
    }
  },
  licensePoint: {
    '& div': {
      display: "none"
    },
    '&:hover': {

      '& div': {
        display: "table",
        marginTop: "-26px",
        marginLeft: "2px"
      },
      '& svg': {
        opacity: "0.6"
      }
    }
  },
  actionButtonWrapper: {
    width: '33%',
    textAlign: 'right'
  },
  activeFilterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    alignContent: 'space-between',
    padding: "0 0 0 8px",
    marginBottom: "5px",
    minHeight: "50px",
  },
  tagWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: 2,
  },
  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  }
};

export default connect(mapStateToProps)(withStyles(styles)(FilterDisplay));

import React from 'react'
import Cell from './Cell'
import $ from 'jquery';
import { connect } from "react-redux";
import getEventBus from "../../../lib/ImageSphereEventBus";
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import { ReactComponent as SelectedImageIcon } from '../../../Assets/selectedImageIcon.svg';
import Hammer from "@egjs/hammerjs";
class Canvas extends React.Component {

  constructor(props) {
    super(props);

    window.canvas = this;

    this.order = [0, 1, 3, 2];  // für Reihenfolge der vier Zellecken: NW, NO, SO, SW

    this.xm = 0;
    this.ym = 0;
    this.xMove = 0;
    this.yMove = 0;

    this.scale_max = 1.34;
    this.distScale_max = 2.84;
    this.pow_max = 1.8;
    this.fillFactor = 1.0;

    this.xDragStart = 0;
    this.yDragStart = 0;
    this.xMouseMove = 0;
    this.yMouseMove = 0;

    this.onMouseUpHandler = this.onMouseUpHandler.bind(this);
    this.onMouseDownHandler = this.onMouseDownHandler.bind(this);
    this._handleDoubleClickItem = this._handleDoubleClickItem.bind(this);
    this._handleMouseHover = this._handleMouseHover.bind(this);
    this.buttonMouseDownHandler = this.buttonMouseDownHandler.bind(this);
    this.buttonWheelHandler = this.buttonWheelHandler.bind(this);
    this.handleWheelEvent = this.handleWheelEvent.bind(this);

//    this.pinchCounter = 0;

    this.state = {
      lastHoverTarget: null
    };

    this.dragVectors = [];

    window.onresize = () => {
      this.hideCenterImageButton();
      this.redrawCanvas();
    };

    window.document.onmouseleave = (e) => {
      this.endDrag(e)
    };
    window.document.onmouseup = (e) => {
      this.endDrag(e)
    };
    this.eventBus = getEventBus()
  }

  initViewport() {
    if (this.state.lastHoverTarget) {
      this.setState({showHoverImage: false});
    }

    // clear the cells
    this.xm = this.ym = 0;
    const nCells = (this.props.gridSideLength) * (this.props.gridSideLength);
    this.cells = new Array(nCells);
    for (let i = 0; i < this.cells.length; i++) {
      this.cells[i] = new Cell();
    }
    this.initSizes();
  }


  initSizes() {
    if (this.props.gridSideLength === 0)
      return;
    this.minOfWidthAndHeight = Math.min(this.height, this.width);
    this.maxOfWidthAndHeight = Math.max(this.height, this.width);
    this.fillFactor = (this.maxOfWidthAndHeight / this.minOfWidthAndHeight);
    this.cellSize = this.minOfWidthAndHeight / (this.props.gridSideLength -1);
    this.cellsPerPixel = 1 / this.cellSize;
    this.setPositionTransformation();

  }

  setPositionTransformation() {
    this.scale = this.fillFactor + this.props.shapeValue * (this.scale_max - this.fillFactor) / 100;
    this.distScale = 1 + this.props.shapeValue * (this.distScale_max - 1) / 100;
    this.pow = this.props.shapeValue * this.pow_max / 100;
  }

  async updateCanvas(xMouseMove, yMouseMove, xDragStart, yDragStart, str) {
    //console.log("updateCanvas", xMouseMove.toFixed(2), yMouseMove.toFixed(2), xDragStart.toFixed(2), yDragStart.toFixed(2), str, this.xm.toFixed(2), this.ym.toFixed(2))
    if (this.cells == null || this.props.imageArray2D == null)
      return;
    //Update translation through zoom and distortion
    const mouseDistX = (this.width / 2 - xDragStart) / (this.scale * this.minOfWidthAndHeight * this.props.zoomFactor); //canvas bezug
    const mouseDistY = (this.height / 2 - yDragStart) / (this.scale * this.minOfWidthAndHeight * this.props.zoomFactor); //canvas bezug
    const relMouseDist = this.getDistance(mouseDistX, mouseDistY, 0.4);
    let factor = this.getTransformFactor(Math.abs(relMouseDist));
    //Update translation
    const xmChange = (xMouseMove * this.cellsPerPixel) / (factor * this.props.zoomFactor);
    this.xm += xmChange;
    this.ym += (yMouseMove * this.cellsPerPixel) / (factor * this.props.zoomFactor);
    
    //console.log("updateCanvas", "xm", this.xm, "xMouseMove", xMouseMove, this.cellsPerPixel, factor, this.props.zoomFactor)


    //Set drawing positions
    const ys = Math.round(this.ym); // beim ganzzahligen Anteil der Verschiebung beginnen
    const xs = Math.round(this.xm); // ganzteilige Verschiebung erfolgt über Indizes, der Rest hier

    let cellIndex = 0;

    for (let y = ys; y < ys + this.props.gridSideLength; y++) {
      for (let x = xs; x < xs + this.props.gridSideLength; x++) {

        let cell = this.cells[cellIndex++];
        // if (!cell) continue
        
        cell.visible = true;
        cell.preview = false;
        let avgDist = 0;

        const outOfBorder = x < 0 || y < 0 || x >= this.props.gridSideLength || y >= this.props.gridSideLength;
        for (let i = 0, dy = 0; dy < 2; dy++) {
          const distY = (y - this.ym - this.props.gridSideLength / 2 + dy) / this.props.gridSideLength;
          for (let dx = 0; dx < 2; dx++ , i++) {
            const distX = (x - this.xm - this.props.gridSideLength / 2 + dx) / this.props.gridSideLength;
            const dist = this.getDistance(distX, distY, 1);
            avgDist += dist;
            factor = this.getTransformFactor(dist);

            if (factor < this.scale)
              cell.visible = false;
            else {
              cell.posX[this.order[i]] = Math.round(this.width / 2) + Math.round(this.cellSize * distX * (this.props.gridSideLength) * factor * this.props.zoomFactor);
              cell.posY[this.order[i]] = Math.round(this.height / 2) + Math.round(this.cellSize * distY * (this.props.gridSideLength) * factor * this.props.zoomFactor);
            }
            if (outOfBorder){
              cell.preview = true;
              //console.log(xs,ys, x,y)
            }
              
          }
        }
        cell.dist = avgDist * 0.25;
        cell.indexX = Math.trunc((x + 1000 * this.props.gridSideLength) % this.props.gridSideLength);
        cell.indexY = Math.trunc((y + 1000 * this.props.gridSideLength) % this.props.gridSideLength);
      }
    }


    this.cells.sort(function (a, b) {
      return b.dist - a.dist;
    });


    const timeSinceFadeout = (+new Date()) - window.Animator.fadeOutStartTime;
    const continueIn = (window.Animator.fadeOutDuration - timeSinceFadeout) > 0 ? (window.Animator.fadeOutDuration - timeSinceFadeout) : 0;

    const wait = ms => new Promise((r) => setTimeout(r, ms));
    await (async () => {
      await wait(continueIn);
    })();

    this.drawImagesOnCanvas(true);
  }

  drawImagesOnCanvas(redraw = true) {

    window.requestAnimationFrame(() => {

      if (redraw) this.clearCanvas(this.ctx);
      this.ctx.globalCompositeOperation = "source-over";

      let imageCounter = 0;  // Zählen wieviele Bilder wir neu laden müssen
      const imagePos = this.cells.length;
      for (let i = 0; i < imagePos; i++) {
        const cell = this.cells[i];
        if (!cell.visible)
          continue;

        let xStart = Math.trunc(Math.floor(cell.posX[0] + cell.posX[3] + 1) / 2);     // (xNW + xSW+1)/2;
        const xEnd = Math.trunc(Math.floor(cell.posX[1] + cell.posX[2] + 1) / 2);     // (xNO + xSO+1)/2;
        let yStart = Math.trunc(Math.floor(cell.posY[0] + cell.posY[1] + 1) / 2);     // (yNW + yNO+1)/2;
        const yEnd = Math.trunc(Math.floor(cell.posY[3] + cell.posY[2] + 1) / 2);	   // (ySW + ySO+1)/2;
        const xSizeMax = xEnd - xStart;
        const ySizeMax = yEnd - yStart;

        const xSize = Math.floor(Math.max(1, xSizeMax * this.props.borderFactor));
        const ySize = Math.floor(Math.max(1, ySizeMax * this.props.borderFactor));
        xStart += Math.trunc(Math.floor(xSizeMax - xSize + 1) / 2); // Startposition
        yStart += Math.trunc(Math.floor(ySizeMax - ySize + 1) / 2);

        let image;
        try {
          image = this.props.imageArray2D[cell.indexY][cell.indexX];
        } catch (error) {
          return;
        }

        if (cell.preview || image === "preview" || !Boolean(image))
          this.drawPreview(xStart, yStart, xSize, ySize, this.ctx);
        else if (image) {
          const alreadyLoaded = this.isImgLoaded(image);
          if (alreadyLoaded) {
            this.drawImage(image, xStart, yStart, xSize, ySize, this.ctx);
          } else {
            imageCounter++;
            const instance = this;
            this.drawPreview(xStart, yStart, xSize, ySize, this.ctx);
            image.onload = () => {

              instance.drawImage(image, xStart, yStart, xSize, ySize, this.ctx);
              imageCounter--;
              if (imageCounter === 0) {
                instance.drawImagesOnCanvas(false);
              }  // wenn alles geladen ist, neu zeichnen wegen der Bildränder

            }
          }
        }
        this.drawImageBorder(xStart, yStart, xSize, ySize, this.ctx);
      }
    });
  }

  isImgLoaded(imgSelector) {
    return $(imgSelector).prop("complete") && $(imgSelector).prop("naturalWidth") !== 0;
  }

  getDistance(dx, dy, max) {
    return Math.min(max, Math.sqrt(dx * dx + dy * dy));
  }

  getTransformFactor(dist) {

    return this.scale * (2 - Math.pow(this.distScale * dist, this.pow));
  }

  clearCanvas(canvas) {
    // const color = this.props.backgroundColor;
    // canvas.fillStyle = 'rgb(' + color + ',' + color + ',' + color + ')';
    canvas.clearRect(0, 0, this.width, this.height);
  }

  /*
  * Raster malen
  */

  /*
   drawCellBorder(cell) {
     this.ctx.strokeStyle = '#000000';
     this.ctx.beginPath();
     this.ctx.moveTo(cell.posX[3], cell.posY[3]);
     for (let i = 0; i < 4; i++) {
       this.ctx.lineTo(cell.posX[i], cell.posY[i]);
     }
     this.ctx.closePath();
     this.ctx.stroke();
   }
   */
  drawImage(image, x, y, width, height, canvas) {
    //Image hält aktuell Promise, daher mit then 'entpacken'
    const halfBorder = Math.trunc(this.props.borderSize / 2);
    canvas.drawImage(image, x + halfBorder, y + halfBorder, width - halfBorder * 2, height - halfBorder * 2);
  }

  /**
   * canvas element zeichnet immer eine centered border der dicke this.props.borderSize
   * @param x
   * @param y
   * @param width
   * @param height
   * @param canvas
   */
  drawImageBorder(x, y, width, height, canvas) {

    canvas.strokeStyle = '#FFFFFF';
    canvas.lineWidth = this.props.borderSize;
    // const halfBorder = parseInt(this.props.borderSize / 2);

    canvas.strokeRect(x, y, width, height);
  }

  drawPreview(x, y, width, height, canvas) {

    let gradient = canvas.createLinearGradient((this.width / 5), 0, this.width, this.height);
    gradient.addColorStop(0, '#AAAAAA');
    // gradient.addColorStop(0.5, '#000000');
    gradient.addColorStop(1, '#1B1C1C');
    canvas.fillStyle = gradient;

    // this.ctx.fillStyle = '#AAAAAA';
    canvas.fillRect(x, y, width, height);
    this.drawImageBorder(x, y, width, height, canvas);
  }

  getCentralImage = () => {
    return this.getTargetImage(this.width / 2, this.height / 2);
  };

  getCentralCell = () => {
    return this.getTargetCell(this.width / 2, this.height / 2);
  };

  getTargetCell(targetX, targetY) {
    for (let index = 0; index < this.cells.length; index++) {
      const cell = this.cells[index];
      if (cell.visible) {

        const xStart = Math.trunc(Math.floor(cell.posX[0] + cell.posX[3] + 1) / 2);   // (xNW + xSW+1)/2;
        const xEnd   = Math.trunc(Math.floor(cell.posX[1] + cell.posX[2] + 1) / 2);   // (xNO + xSO+1)/2;
        const yStart = Math.trunc(Math.floor(cell.posY[0] + cell.posY[1] + 1) / 2);   // (yNW + yNO+1)/2;
        const yEnd   = Math.trunc(Math.floor(cell.posY[3] + cell.posY[2] + 1) / 2);	 // (ySW + ySO+1)/2;

        if (targetX >= xStart && targetX <= xEnd && targetY >= yStart && targetY <= yEnd) {
          const xSizeMax = xEnd - xStart;
          const ySizeMax = yEnd - yStart;
          const xSize = Math.floor(Math.max(1, xSizeMax * this.props.borderFactor));
          const ySize = Math.floor(Math.max(1, ySizeMax * this.props.borderFactor));
          const sx = Math.trunc(Math.floor(xSizeMax - xSize + 1) / 2); // Startposition
          const sy = Math.trunc(Math.floor(ySizeMax - ySize + 1) / 2);

          const result = {};

          result['posx'] = cell.indexX;
          result['posy'] = cell.indexY;
          result['coordx'] = xStart + sx;
          result['coordy'] = yStart + sy;
          result['xSize'] = xSize;
          result['ySize'] = ySize;

          return result;
        }
      }

    }
  }

  /**
   * @param targetX: x Mouse position on the canvas
   * @param targetY: y Mouse position on the canvas
   */
  getTargetImage = function (targetX, targetY) {

    for (let index = 0; index < this.cells.length; index++) {
      const cell = this.cells[index];
      if (cell.visible) {

        let image;
        try {
          image = this.props.imageArray2D[cell.indexY][cell.indexX];
        } catch (error) {
          image = null;
        }
        if (image != null) { // !bi ist eine Lücke im Netz
          const xStart = Math.trunc(Math.floor(cell.posX[0] + cell.posX[3] + 1) / 2);   // (xNW + xSW+1)/2;
          const xEnd   = Math.trunc(Math.floor(cell.posX[1] + cell.posX[2] + 1) / 2);   // (xNO + xSO+1)/2;
          const yStart = Math.trunc(Math.floor(cell.posY[0] + cell.posY[1] + 1) / 2);   // (yNW + yNO+1)/2;
          const yEnd   = Math.trunc(Math.floor(cell.posY[3] + cell.posY[2] + 1) / 2);	 // (ySW + ySO+1)/2;

          if (targetX >= xStart && targetX <= xEnd && targetY >= yStart && targetY <= yEnd) {
            const xSizeMax = xEnd - xStart;
            const ySizeMax = yEnd - yStart;
            const xSize = Math.floor(Math.max(1, xSizeMax * this.props.borderFactor));
            const ySize = Math.floor(Math.max(1, ySizeMax * this.props.borderFactor));
            const sx = Math.trunc(Math.floor(xSizeMax - xSize + 1) / 2); // Startposition
            const sy = Math.trunc(Math.floor(ySizeMax - ySize + 1) / 2);

            const result = image;
            if (!result.setAttribute) return;
            result.setAttribute('posx', cell.indexX);
            result.setAttribute('posy', cell.indexY);
            result.setAttribute('coordx', xStart + sx);
            result.setAttribute('coordy', yStart + sy);
            result.setAttribute('xSize', xSize);
            result.setAttribute('ySize', ySize);

            return result;
          }
        }
      }
    }
  };

  /**
   * TODO liefert nicht das genaue Zentrumsbild
   * @return {null}
   */
  getClosestImageToCenter() {
    let closestImage = window.canvas.getCentralImage();
    if(closestImage) return closestImage;

    const mX = this.width / 2;
    const mY = this.height / 2;
    let minDist = Number.MAX_VALUE;
    for (let index = 0; index < this.cells.length; index++) {
      const cell = this.cells[index];
      if (cell.visible) {

        const image = this.props.imageArray2D[cell.indexY][cell.indexX];
        if (image && image !== "preview") { // !bi ist eine Lücke im Netz
          const xStart = Math.trunc(Math.floor(cell.posX[0] + cell.posX[3] + 1) / 2);    // (xNW + xSW+1)/2;
          const xEnd   = Math.trunc(Math.floor(cell.posX[1] + cell.posX[2] + 1) / 2);    // (xNO + xSO+1)/2;
          const yStart = Math.trunc(Math.floor(cell.posY[0] + cell.posY[1] + 1) / 2);    // (yNW + yNO+1)/2;
          const yEnd   = Math.trunc(Math.floor(cell.posY[3] + cell.posY[2] + 1) / 2);    // (ySW + ySO+1)/2;
          const xSizeMax = xEnd - xStart;
          const ySizeMax = yEnd - yStart;
          const xSize = Math.floor(Math.max(1, xSizeMax * this.props.borderFactor));
          const ySize = Math.floor(Math.max(1, ySizeMax * this.props.borderFactor));
          const sx = Math.trunc(Math.floor(xSizeMax - xSize + 1) / 2); // Startposition
          const sy = Math.trunc(Math.floor(ySizeMax - ySize + 1) / 2);

          const dist = Math.pow(mX - xStart + sx, 2) + Math.pow(mY - yStart + sy, 2);

          if (dist < minDist) {
            minDist = dist;
            closestImage = image;
            closestImage.setAttribute('posx', cell.indexX);
            closestImage.setAttribute('posy', cell.indexY);
            closestImage.setAttribute('coordx', xStart + sx);
            closestImage.setAttribute('coordy', yStart + sy);
            closestImage.setAttribute('xSize', xSize);
            closestImage.setAttribute('ySize', ySize);
          }
        }
      }
    }

    return closestImage;
  }

  getCentralImagePosition() {
    //console.log("getCentralImagePosition: ")
    const centralImage = this.getCentralImage();

    if (centralImage) {
      const posX = centralImage.getAttribute('posx');
      const posY = centralImage.getAttribute('posY');
      return {posX, posY};
    } else {
      return {posX: Math.floor(this.props.gridSideLength / 2), posY: Math.floor(this.props.gridSideLength / 2)};
    }
  }

  hideCenterImageButton(fast){
    if(fast) this.singleClickFired = false;
    
    this.setState({showHoverImage: false, lastHoverTarget: null});
    if(Boolean(this.toolTipTimeout)){
      clearTimeout(this.toolTipTimeout);
    }
  }

  redrawCanvas() {
    
    const rect = document.getElementById("mainCanvas").parentNode.getBoundingClientRect();
    this.ctx.canvas.width = this.width = rect.width;
    this.ctx.canvas.height = this.height = rect.height;

    if (this.props.imageArray2D) {
      this.initSizes();
      this.updateCanvas(0, 0, 0, 0, "redrawCanvas");
    }
  }

  /**
   *  Lifecycle methods
   *  @override
   */
  componentDidMount() {
    this.ctx = this.canvas.getContext('2d');
    const rect = document.getElementById("mainCanvas").parentNode.getBoundingClientRect();
    this.ctx.canvas.width = this.width = rect.width;
    this.ctx.canvas.height = this.height = rect.height;

    //this.initZoomEvents();
    this.initViewport();
    this.initHammerJs();
  }

  initHammerJs(){
    const canvasDom = document.querySelector('#mainCanvas')

    const pinchRecognizer = new Hammer.Pinch({enable: true});
    const tapRecognizer = new Hammer.Tap({event: 'doubletap', taps: 2 });
    const manager = new Hammer.Manager(canvasDom);

    manager.add(pinchRecognizer);
    manager.add(tapRecognizer);

    manager.on("doubletap", (e)=> {
      if (e.pointerType === "mouse") return e.preventDefault(e);
      e.preventDefault(e);
      this._handleDoubleClickItem(e, true);
    });

    manager.on("pinchin", (event)=> {
      if(!this.zoomed);
        this.zoom(true, event.center.x, event.center.y)
    });
    manager.on("pinchout", (event)=> {
      if(!this.zoomed);
        this.zoom(false, event.center.x, event.center.y)
    });
    manager.on("pinchstart", (e)=> this.pinching = true);
    manager.on("pinchend", (e)=> {
      this.pinching = false;
      this.zoomed = false;
    });
  }

  async zoom(zoomIn=true, x, y){
    //this.pinchCounter += 1;
    this.zoomed = true;
    const boundingBox = document.getElementById("mainCanvas").getBoundingClientRect();
    const targetImage = this.getTargetImage(x - boundingBox.left, y - boundingBox.top);
    const levelDiff = zoomIn ? 1 :  -1;
    try {
      await this.props.handleZoom(targetImage, this.props.level + levelDiff);
      //this.pinchCounter = `SUCCES level: ${this.props.level} levelDiff: ${levelDiff} zooming ${this.zooming} image ${image}`
    } catch (error) {
      //this.pinchCounter = `${error} ERROR level: ${this.props.level} levelDiff: ${levelDiff} zooming ${this.zooming} image ${image}`
    }
  }

  /**
   *
   * https://engineering.opsgenie.com/i-wish-i-knew-these-before-diving-into-react-301e0ee2e488
   *
   * @override
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.gridSideLength !== prevProps.gridSideLength) {
      this.initViewport();
    }
    if (this.props.imageArray2D !== prevProps.imageArray2D) {

      //After drag only use after floating point numbers

      this.xm = this.xm - this.xMove; // % 1
      this.ym = this.ym - this.yMove; // % 1

      this.xMove = 0;
      this.yMove = 0;

      this.updateCanvas(this.xMouseMove, this.yMouseMove, this.xDragStart, this.yDragStart, "A");
    }
    if (this.props.shapeValue !== prevProps.shapeValue) {
      this.setPositionTransformation();
      this.updateCanvas(this.xMouseMove, this.yMouseMove, this.xDragStart, this.yDragStart, "B");
    }
    if (this.props.backgroundColor !== prevProps.backgroundColor) {
      this.updateCanvas(this.xMouseMove, this.yMouseMove, this.xDragStart, this.yDragStart, "E");
    }
    if (this.props.borderFactor !== prevProps.borderFactor) {
      this.updateCanvas(this.xMouseMove, this.yMouseMove, this.xDragStart, this.yDragStart, "C");
    }

    this.handleAutoRotate();
  }


  /**
  *  Event Handling
  */
  onMouseDownHandler = (e) => {
   
    if (this.isBusy) 
      return e.preventDefault();

    this.singleClickFired =  true;
    if(this.toolTipTimeout){
      clearTimeout(this.toolTipTimeout)
    }
    this.stopSkimming();
    if (e.button === 0) {
      this.xDragStart = parseInt(e.clientX);
      this.yDragStart = parseInt(e.clientY);
      this.isMouseDown = true;
      this.dragged = false;
      return
    }

    if (Boolean(e.changedTouches) && e.changedTouches.length !== 0) {
      this.xDragStart = parseInt(e.changedTouches[0].clientX);
      this.yDragStart = parseInt(e.changedTouches[0].clientY);
      this.isMouseDown = true;
      this.dragged = false;
    }
  };

  mouseMoveHandler = (e) => {
    if (this.isBusy || this.pinching) return e.preventDefault();

    const isTouch = Boolean(e.changedTouches);
    const clientX = isTouch ? e.changedTouches[0].clientX : e.clientX;
    const clientY = isTouch ? e.changedTouches[0].clientY : e.clientY;
    this.mousePositionX = clientX;
    this.mousePositionY = clientY;

    if (this.isMouseDown && (e.button === 0 || isTouch)) {
      if (this.state.lastHoverTarget){
        //window.Animator.removeTooltipImage(this.state.lastHoverTarget);
        this.setState({showHoverImage: false, lastHoverTarget: null});
      }

      
      const xDragDist = this.xDragStart - clientX;
      const yDragDist = this.yDragStart - clientY;

      if(Math.abs(xDragDist) < 5 && Math.abs(yDragDist < 5)) return

      //Dragging
      this.dragged = true;
      this.xMouseMove += xDragDist;
      this.yMouseMove += yDragDist;

      this.storeDragVector(this.xMouseMove, this.yMouseMove);

      this.updateCanvas(this.xMouseMove, this.yMouseMove, this.xDragStart, this.yDragStart, "mouseMoveHandler");
      this.xMouseMove = 0;
      this.yMouseMove = 0;
      this.xDragStart = clientX;
      this.yDragStart = clientY;

      //console.log(Math.abs(this.xm), this.xMove, this.ym, this.yMove)
      
      const absXm  = Math.abs(this.xm);
      const absYm  = Math.abs(this.ym);
      
      if (((absXm ) > 3 ) || ((absYm) > 3)){
        this.endSkimming(false)
      }
      
      //when mouse button is hold down and mouse is not moving, prevent skimming after final release
      if (this.endSkimmingWhenNoMovementTimeout){
        clearTimeout(this.endSkimmingWhenNoMovementTimeout)
      }
      this.endSkimmingWhenNoMovementTimeout = setTimeout(() => {
        if(!this.skimming){
          this.stopSkimming()
        }
      }, 100); 

    } else {
      
      this.setState({showHoverImage: false, lastHoverTarget: null});
      const self = this;
      
      const boundingBox = document.getElementById("mainCanvas").getBoundingClientRect();
      const targetImage = self.getTargetImage(clientX - boundingBox.left, clientY - boundingBox.top);
      
       
      if (this.toolTipTimeout){
        clearTimeout(this.toolTipTimeout);
      }
      if(this.state.lastHoverTarget === targetImage) return;

      if(!targetImage || this.skimming || this.isBusy){
        this.setState({showHoverImage: false, lastHoverTarget: null});
        return
      }

      // this.showCenterImageButtonAfterTimeout(targetImage, boundingBox);
    }
    
  };

  showCenterImageButtonAfterTimeout(image, boundingBox,locX, locY, timeout=1000){
    this.toolTipTimeout = setTimeout(() => {

      if(Boolean(locX) && Boolean(locY)){
        image = this.getTargetImage(locX, locY);
      }
      if(!Boolean(image)){
        return;
      }
      const imgClone = image.cloneNode(true);
      imgClone.posLeft =  boundingBox.x + parseInt(imgClone.getAttribute("coordx"));
      this.setState({'lastHoverTarget': imgClone, showHoverImage: true});
      this.dontLeave = false

    }, timeout); 
  }

  showSearchAnimation(targetImage){
    const top = parseInt(targetImage.getAttribute('coordy'));
    const left = parseInt(targetImage.getAttribute('coordx'));
    const width = parseInt(targetImage.getAttribute('xsize'));
    const height = parseInt(targetImage.getAttribute('ysize'));
    
    window.Animator.findSimilar(targetImage.cloneNode(true), { top, left, width, height }, { prepareWithOffset: true });
  }

  _handleDoubleClickItem(e, tapped=false) {
    const boundingBox = document.getElementById("mainCanvas").getBoundingClientRect();
    const x = e.clientX || e.center.x;
    const y = e.clientY || e.center.y;
    const targetImage = this.getTargetImage(x - boundingBox.left, y - boundingBox.top);
  
    if (targetImage) {
      if(this.state.lastHoverTarget){
        this.setState({showHoverImage: false});
        this.setState({lastHoverTarget: null});
      }
      this.dontLeave = true;
      this.showSearchAnimation(targetImage);
      this.eventBus.publish('ImageClicked', {"mode": "double", "image": targetImage});

      window.CanvasContainer.updateCentralImage(targetImage.name, 0, targetImage.id);
      window.CanvasContainer.searchByImage(targetImage.id, false, true);
      // if(!tapped)
      //   this.showCenterImageButtonAfterTimeout(targetImage, boundingBox,e.clientX - boundingBox.left, e.clientY - boundingBox.top, 2000);
    }
  }

  _handleMouseHover(e) {
    return
    // const boundingBox = document.getElementById("mainCanvas").getBoundingClientRect();
    // const targetImage = this.getTargetImage(e.clientX - boundingBox.left, e.clientY - boundingBox.top);
    // if (targetImage && this.state.lastHoverTarget !== targetImage)
    //     this.setState({'lastHoverTarget': targetImage, showHoverImage: true});
  }

  handleClick(e){
    if(this.toolTipTimeout){
      clearTimeout(this.toolTipTimeout)
    }
      

    const boundingBox = document.getElementById("mainCanvas").getBoundingClientRect();
    const x = e.clientX || e.center.x;
    const y = e.clientY || e.center.y;
    const targetImage = this.getTargetImage(x - boundingBox.left, y - boundingBox.top);

    if(!Boolean(targetImage)) return;
    if (this.clickedBefore || (Boolean(this.state.lastHoverTarget) && (targetImage.id === this.state.lastHoverTarget.id))){
      clearTimeout(this.showDetailsTimout);
      this._handleDoubleClickItem(e)
    }else{
      this.clickedBefore = true;

      clearTimeout(this.singeClickTimeout);
      this.singeClickTimeout = setTimeout(() => {
        if (targetImage) {
          this.clickedBefore = false;
        }
      }, 500);

      this.showDetailsTimout = setTimeout(() => {
        if (targetImage) {
          this.eventBus.publish('ImageClicked', {"mode": "single","image": targetImage});
        }
      }, 250);

      
      this.showCenterImageButtonAfterTimeout(targetImage, boundingBox, false, false, 0);
      
    }
  }

  onMouseUpHandler = async (e) => {

    this.singleClickFired = false; 
    if (this.isBusy) return e.preventDefault();
    else e.persist();

    if (!this.dragged && e.button === 0) {
      this.handleClick(e);
    }
    if(this.dragged){
      this.endDrag(e);
    }
      
  };

  endDrag = async (e) => {
    this.isMouseDown = false;
    if (this.dragged && (e.button === 0 || e.changedTouches.length !== 0)) {
      const deltaX  = this.xm-this.xMove;
      const deltaY = this.ym-this.yMove;

      // also show clicked image if drag was rather small
      if ((Math.abs(deltaX) + Math.abs(deltaY)) < 0.25)
        this.handleClick(e);
      
      this.props.handleDrag(deltaX, deltaY);  
      this.xMove = Math.round(this.xm);
      this.yMove = Math.round(this.ym);
      //console.log("dx", deltaX, "xm", this.xm, "xMove", this.xMove, "dy", deltaY, "ym", this.ym, "yMove", this.yMove)
      
      const xMouse = parseInt(e.clientX);
      const yMouse = parseInt(e.clientY);
      const xDragDist = this.xDragStart - xMouse;
      const yDragDist = this.yDragStart - yMouse;
      this.storeDragVector(xDragDist, yDragDist);
      
      this.skimSphere();
      this.dragged = false;
    }
  };

  skimSphere() {
    
    this.skimming = true;
    let dragVelocityVector = this.getDragVelocityVector();
    /*
    this.clearCanvas();
    this.ctx.font = "20px Georgia";
    this.ctx.fillStyle = "black";
    let str = "vx: " + dragVelocityVector.x.toFixed(2) + "   vy: " + dragVelocityVector.y.toFixed(2);
    this.ctx.fillText(str, 30, 30);
    */
    //console.log("skimSphere", dragVelocityVector.x, dragVelocityVector.y)
    clearInterval(this.skimInterval);
    this.skimInterval = setInterval(() => {
      if (this.getVectorLength(dragVelocityVector) > 0.1) {
        this.updateCanvas(dragVelocityVector.x, dragVelocityVector.y, this.xDragStart, this.yDragStart, "skimSphere2");
        dragVelocityVector.x = dragVelocityVector.x * 0.91; 
        dragVelocityVector.y = dragVelocityVector.y * 0.91; 
      } else {
        this.endSkimming();
      }
    }, 10);
  }

  endSkimming(showCenterButton=true) {
    this.props.handleDrag(this.xm-this.xMove, this.ym-this.yMove, true);  
    this.xMove = Math.round(this.xm);
    this.yMove = Math.round(this.ym);
    this.skimming = false;
    clearInterval(this.skimInterval);
    // const boundingBox = document.getElementById("mainCanvas").getBoundingClientRect();
    // const x = this.mousePositionX;
    // const y = this.mousePositionY;
    // if(showCenterButton && !(('createTouch' in document) || ('onstarttouch' in window)))
    //   this.showCenterImageButtonAfterTimeout(null, boundingBox,x - boundingBox.left, y - boundingBox.top, 1000);
  }

  stopSkimming() {
    clearInterval(this.skimInterval);
    this.dragVectors = [];
  }

  storeDragVector(x, y) {
    this.dragVectors.push({
      x,
      y
    });
  }

  getDragVelocityVector() {
    const lastDrags = this.dragVectors.slice(this.dragVectors.length - 5, this.dragVectors.length);
    if (lastDrags.length < 5) // weniger als 5 Dragschritte
      return { x: 0, y: 0 };

    let x = (6*lastDrags[0].x + lastDrags[1].x + lastDrags[2].x + lastDrags[3].x + lastDrags[4].x) / 10;
    let y = (6*lastDrags[0].y + lastDrags[1].y + lastDrags[2].y + lastDrags[3].y + lastDrags[4].y) / 10;
    let length = Math.sqrt(x * x + y * y);
    let maxLength = 20;
    if (length > maxLength) {     // maximale Draggeschwindigkeit
      x = x / length * maxLength;
      y = y / length * maxLength;
    }
    /*
    if (length < 1) {      
      x = 0;
      y = 0;
    }
    */
    return {
      x,
      y
    }
  }

  getVectorLength(vector) {
    return Math.sqrt(vector.x * vector.x + vector.y * vector.y);
  }

  handleWheelEvent(e) {
    if (this.isBusy) return;

    const boundingBox = document.getElementById("mainCanvas").getBoundingClientRect();

    let targetImage = this.getTargetImage(e.clientX - boundingBox.left, e.clientY - boundingBox.top) || this.getCentralImage(); //If zooming outside of canvas, preserve central image
    if (!targetImage) return;

    if (e.deltaY < -6) {
      this.props.handleZoom(targetImage, this.props.level - 1);
    }
    if (e.deltaY > 6) {
      this.props.handleZoom(targetImage, this.props.level + 1);
    }
  
  }

  handleAutoRotate() {
    if (this.props.autoRotate && !this.autoRotationRunning) {
      this.autoRotationRunning = true;
      this.autoRotateUpdateCanvasInterval = setInterval(() => {
        this.updateCanvas(-0.5, -0.3, 0, 0);
      }, 15);
      this.autoRotateHandleDragInterval = setInterval(() => {
        this.xMove = Math.trunc(this.xm);
        this.yMove = Math.trunc(this.ym);
        this.props.handleDrag(this.xm, this.ym);
      }, 5000);
    } else if (!this.props.autoRotate && this.autoRotationRunning) {
      this.autoRotationRunning = false;
      clearInterval(this.autoRotateUpdateCanvasInterval);
      clearInterval(this.autoRotateHandleDragInterval);
    }
  }

  buttonWheelHandler(e){
    this.setState({showHoverImage:false});
    this.handleWheelEvent(e);
  }

  buttonMouseDownHandler(e){
      e.preventDefault();
      this.setState({showHoverImage:false});
      this.onMouseDownHandler(e);
  }

  renderShowSimilarButton(){
    const {lastHoverTarget, showHoverImage} = this.state;
    if(lastHoverTarget && showHoverImage){
      const height = parseInt(lastHoverTarget.getAttribute("ysize")) ;
      const width = parseInt(lastHoverTarget.getAttribute("xsize"));
      const top =  parseInt(lastHoverTarget.getAttribute("coordy"));
      const left = lastHoverTarget.posLeft + parseInt(lastHoverTarget.getAttribute("xsize")) - width;

      const exitDuration = !this.singleClickFired ? 300 : 0;
      return(
        <Fade in={showHoverImage} timeout={{enter: 300, exit: exitDuration}}>
          <Button 
                  color="secondary"
                  onMouseUp={this.onMouseUpHandler}
                  onWheel={this.buttonWheelHandler}
                  onMouseDown={this.buttonMouseDownHandler}
                  onTouchStart={this.onMouseDownHandler}
                  onTouchEnd={this.onMouseUpHandler}
                  onTouchMove={this.mouseMoveHandler}
                  variant="contained"
                  id="centerImageButton"
                  style={{
                    position: "absolute",
                    top: top,
                    left: left,
                    zIndex: "77",
                    minWidth: 0,
                    width: width,
                    height: height,
                    backgroundColor: "#00649900",
                    userDrag: "none", 
                    userSelect: "none",
                    padding: 0
                  }}>
              <SelectedImageIcon alt="click to center" style={{
                  width: width,
                  height: height,
                  userDrag: "none",
                  userSelect: "none"
                }}/>
          </Button>
        </Fade>
      )
    }
  }


  render() {
    return (
      <div style={{height:"100%", width: "100%"}}>
        {this.renderShowSimilarButton()}
          <canvas
          style={styles.canvas}
          id='mainCanvas'
          ref={ref => this.canvas = ref}
          onMouseUp={this.onMouseUpHandler}
          onMouseDown={this.onMouseDownHandler}
          onMouseMove={this.mouseMoveHandler}
          onDoubleClick={(e)=> e.preventDefault()}
          onTouchStart={this.onMouseDownHandler}
          onTouchEnd={this.onMouseUpHandler}
          onTouchMove={this.mouseMoveHandler}
          onWheel={this.handleWheelEvent}
          onMouseLeave={()=>{
            if(!Boolean(this.dontLeave)){
              clearTimeout(this.toolTipTimeout);
            }
            
          }}
      />
        
      </div>
    )
  }
}

function mapStateToProps(state) {
  
  return {
    gridSideLength: state.settings.gridSideLength,
    shapeValue: state.settings.shapeValue,
    zoomFactor: state.settings.zoomFactor,
    borderFactor: state.settings.imageSpace,
    autoRotate: state.settings.autoRotate,
    backgroundColor: state.settings.backgroundColor,
    level: state.level.level,
    borderSize: 2,
    isBusy: false,
    datasets: state.settings.availableDatasets
  }
}



let isMobile =  false;
if(window.innerWidth <= 800 && window.innerHeight <= 600) {
  isMobile =  true;
}

const styles = {
  canvas: {
    "WebkitFilter": "contrast(105%)",
    "cursor": isMobile ? 'pointer' : "auto",
  }
};

export default connect(mapStateToProps)(Canvas);




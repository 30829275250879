export const createKey = (filterName)=>{

    return filterName+Math.random().toString(16).slice(10);
}

export const addFilter = (obj, myList) =>{
    myList = myList.slice();
    const key = obj.key;
    const index = myList.findIndex(item => item.key === key); // check if object with key exists
  
    if (index === -1) {
      // if object with key does not exist, add it to the list
      myList.push(obj);
    } else {
      // if object with key exists, update it in the list
      myList[index] = obj;
    }
    return myList
  }
  
// define the remove method
export const removeFilter = (obj, myList) => {
    myList = myList.slice();
    const key = obj.key;
    const index = myList.findIndex(item => item.key === key); // find the index of the object with the key
  
    if (index !== -1) {
      // if the object with the key exists in the list, remove it
      myList.splice(index, 1);
    }
    return myList
  }
import React from 'react';
import { connect } from "react-redux";
import { Grid, TextField, IconButton } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { createKey, addFilter } from "./utils";

import {
    action_change_filters,
} from '../../../Modules/redux/actions';

class RangeFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            min: 0,
            max: 0,
            value: 0,
            isMaxActive: true,
        };
        this.handleIncrement = this.handleIncrement.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeMax = this.handleChangeMax.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleIncrement() {
        const { isMaxActive } = this.state;
        if (isMaxActive) {
            this.setState({
                isMaxActive: false,
                value: this.state.min
            });
        } else {
            this.setState({
                isMaxActive: true,
                min: this.state.value
            });
        }
    }

    handleChange(event) {
        const { value } = event.target;
        const { isMaxActive } = this.state;


        if (isMaxActive) {
            this.setState({
                min: parseInt(value),
            });
        } else {
            this.setState({
                value: parseInt(value),
            });
        }
        // console.log(value, isMaxActive, this.state)
    }

    handleChangeMax(event) {
        const { value } = event.target;
        this.setState({
            max: parseInt(value)
        });
    }

    handleConfirm() {

        let valObj




        const key = createKey(this.props.filterName)

        let displayValue = `${this.props.prefix}: `

        if (this.state.isMaxActive) {
            displayValue += `${this.state.min} - ${this.state.max}`
            valObj = {
                min: this.state.min,
                max: this.state.max,
            }
        } else {
            displayValue += `= ${this.state.value}`
            valObj = { value: this.state.value, }
        }

        displayValue += this.props.suffix
        const filterObj = {
            key: key,
            name: this.props.filterName,
            values: valObj,
            displayValue: displayValue
        }

        const newList = addFilter(filterObj, this.props.filterList)

        this.props.dispatch(action_change_filters(newList));

        this.setState({
            min: 0,
            max: 0,
            value: 0
        });
    }

    render() {
        const { isMaxActive, min, max, value } = this.state;

        return (
            <div className={this.props.classes.rootContainer}>
                <p className={this.props.classes.filterText}>{this.props.prefix}</p>
                <hr />
                <Grid container={true} className={this.props.classes.valueContainer} spacing={3}>
                    <Grid item xs={12} sm={5} md={5}>
                        <TextField
                            label={isMaxActive ? 'min' : 'value'}
                            type="number"
                            value={isMaxActive ? min : value}
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </Grid>
                    {isMaxActive && (
                        <Grid item xs={12} sm={5} md={5}>
                            <TextField label="max" type="number" value={max} fullWidth onChange={this.handleChangeMax} />
                        </Grid>
                    )}
                    {/* <Grid item xs={12} sm={4} md={3}>

                        <IconButton
                            edge="end"
                            aria-label="Add Max Field"
                            className={this.props.classes.filterToggleButton}
                            color="secondary"
                            onClick={this.handleIncrement}>
                            {isMaxActive ? <CloseIcon /> : <AddIcon />}
                        </IconButton>
                    </Grid> */}

                    <IconButton
                        edge="end"
                        aria-label="Confirm"
                        className={this.props.classes.confirmButton}
                        color="secondary"
                        // style={{"marginTop": "-10px"}}
                        onClick={this.handleConfirm}>
                        <CheckIcon />
                    </IconButton>

                </Grid>
            </div>
        );
    }
}

const styles = ({

    rootContainer: {
        margin: '5px',
        marginBottom: "15px",
        display: "flex",
        flexDirection: 'column',
    },
    filterText: {
        margin: "auto",
        padding: "2px",
    },
    valueContainer: {
        marginTop: "5px",
        display: "flex"
    },
    confirmButton: {
        marginLeft: "auto",
        marginRight: "10px"
    }
});

function mapStateToProps(state) {
    return {
        filterList: state.filter.filterList,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RangeFilter));
